import { ADMINKEY } from "../keyvariable";
import { HashLink } from 'react-router-hash-link';

function AdminMenu  (){
    return(
        <div className="admin ">

            <h3 className="pb-5">HELLO, ADMIN</h3>
            <HashLink id="menulinks" smooth to={`/${ADMINKEY}`} ><h3 id="textmenu">DASHBOARD</h3></HashLink>
            <hr/>
            <HashLink id="menulinks" smooth to='/schedule'><h3 id="textmenu">SCHEDULE</h3></HashLink>
            <hr/>
            <a id="menulinks" href="#"><h3 id="textmenu">OUR TEAM</h3></a>
            <hr/>
            <a id="menulinks" href="#"><h3 id="textmenu">HOME</h3></a>
            <hr/>
            <a id="menulinks" href="#"><h3 id="textmenu">NEWS</h3></a>
            <hr/>
            <a id="menulinks" href="#"><h3 id="textmenu">PARTNERS</h3></a>
            <hr/>
            <HashLink id="menulinks" smooth to='/upload'><h3 id="textmenu">ANNOUNCEMENT</h3></HashLink>
            <hr/>
            <a id="menulinks" href="#"><h3 id="textmenu">CHAT</h3></a>
            <hr/>

          
        </div>
    )
} 

export default AdminMenu;

// source : https://www.youtube.com/watch?v=YH63fnqG7F0
// using mysql: https://www.youtube.com/watch?v=dLXSJdTK9QI