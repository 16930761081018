import axios from "axios";
import { useEffect, useState, useRef } from 'react';
import {SERVER} from "../../keyvariable";
const Tester = ()=>{
  const [photos, setPhotos] = useState([])
  
  useEffect(()=>{
    axios.get(SERVER + "upload").then(res=>{
      setPhotos(res.data)
    })
  }, [])

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleUpload = async () => {
    const formData = new FormData();

    for (const file of selectedFiles) {
      formData.append('photos', file);
    }

    try {
      await axios.post(SERVER+'upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res=>{
        console.log(res.data);
      });

    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };
  return(
    <div style={{paddingTop:"100px", minHeight:"100vh"}}>
        <h1>Gallery Upload</h1>
        <input type="file" multiple onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload Photos</button>
    </div>
  )

}


export default Tester;