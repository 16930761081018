import logo from "../assets/logo/Euodoo-copy.png";
import chatlogo from "../assets/logo/Euodoo.png";
import axios from "axios";
import {useState, useEffect} from "react";
import { SERVER } from "../keyvariable";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { createElement } from "react";


const Chattest = ()=>{

    var name = ""; 
    var email = "";
    
    const [chatuser , setChatuser] = useState([]);

    useEffect(()=>{
        
        //fetch data
        name = getExpiry("name");
        email = getExpiry("email");
        const popup = getExpiry("pop");
        const chatBody = document.querySelector('.chat-body');
        const chat = s_getExpiry("chat");
        

        if(popup === null){
            setTimeout((e) => {
            const pop = document.getElementById("close-pop");
            const badge = document.getElementById("badge");
            pop.style.display = 'block';
            badge.style.visibility = 'visible';
            }, 2000);       
        }

        if(name !== null && email !== null){
            if(chat !== null){
            chatBody.innerHTML = '';
            document.querySelector(".loading").style.display = "none";
            document.getElementById("form-header").style.display = "flex";
            document.getElementById("sectionChat").style.display = "none";
            
            const overlay = document.createElement("div");
            overlay.setAttribute("class", "overlay overlay_2");

            const div = document.createElement("div");
            const text = document.createElement("p");
            text.innerText = "Our session has ended.";
            text.setAttribute("class", "rtext");
            div.append(text);

            div.setAttribute("class","renewDiv");
            const renewChat = document.createElement('button');
            renewChat.innerText = data.ending_convo.renew;
            renewChat.setAttribute("id", "link");
            renewChat.addEventListener("click", restart);
            div.append(renewChat);

            overlay.append(div);
            chatBody.innerHTML = chat;

            chatBody.append(overlay);
            }
            else{
                startChat()
            }
        }
        
        axios.get(SERVER + "chats").then(res=>{
            setChatuser(res.data);
        })
    },[])

    const getinput = (e)=>{

        name = document.getElementById("name").value
        email = document.getElementById("email").value

        if (typeof(Storage) !== "undefined") {
            // Store to localstorage
            setExpiry("name", name, 12*60*60*1000); //12 hours
            setExpiry("email", email, 12*60*60*1000); //12 hours
        } else {
           alert("Your Browser doesn't support Web Storage")
        }

    }

    const addchatuser = (e) =>{   

        e.preventDefault();
        const info = {
            name : name, email : email
        }

        var emailSame = false;
        console.log(chatuser.length);
        for(var i=0; i<chatuser.length; i++){
            
            if (info.email === chatuser[i].email){
                emailSame=true;
            }
            
        }

        if(emailSame===false){
            console.log("different");
            axios.post(SERVER + "chats", info);
        }
       
        
    }

    //localstorage
    function setExpiry(key, value, ttl) {
        const now = new Date()

        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    function getExpiry(key) {
        const itemStr = localStorage.getItem(key)

        if (!itemStr) {
            return null
        }

        const item = JSON.parse(itemStr)
        const now = new Date()

        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key)
            return null
        }
        return item.value
    }

    //sesionstorage
    function s_setExpiry(key, value, ttl) {
        const now = new Date()

        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        sessionStorage.setItem(key, JSON.stringify(item))
    }

    function s_getExpiry(key) {
        const itemStr = sessionStorage.getItem(key)

        if (!itemStr) {
            return null
        }

        const item = JSON.parse(itemStr)
        const now = new Date()

        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            sessionStorage.removeItem(key)
            return null
        }
        return item.value
    }

    function closeForm() {
        document.getElementById("myForm").style.display = "none";
    }

    function closePop() {
        document.getElementById("close-pop").style.display = "none";
        setExpiry("pop", true, 12*60*60*1000);
    }

    const no = (e)=>{
        if (e && e.preventDefault) {
            e.preventDefault();
            e.persist();
        }
        document.querySelector(".loading").style.display = "inline-block";
        setTimeout(() => {
            document.querySelector(".loading").style.display = "none";
            showEnding(data.ending_convo.answer);
        }, 2000);

        setTimeout(() => {
            document.querySelector(".loading").style.display = "none";
            renewChat(data.ending_convo.renew);
        }, 3000);
    }
    var data = {
        chatinit:{
            signwelcome: ["Hi! Welcome to Euodoó Tech. We're glad you visit our page. <br/><br/> To start our conversation, please leave your email and name below."],
            welcome: ["Hi! We're glad you visit us today. How may we assist you?"],
            options: ["I want to know what euodoó offers.",
            "I have a question about the company's data security.",
            "What is your pricing structure for IT services?",
            "Where can I learn more about opportunities with Euodoo and how should I apply?"],
        },

        "I want to know what euodoó offers.": {
            answer: ["We'd be happy to answer your questions. What do you want to know about us?"],
            options: ["What does your company specialize in?", 
            "What types of IT services do you offer?",
            "Do you have any certifications or partnerships with major technology vendors?",
            "I want to ask other things.",
            ]
        },

        "What does your company specialize in?":{
            answer: ["Euodoó Technologies specializes in offering cutting-edge software solutions for companies, integrating various systems and technologies to improve productivity. Also Euodoo Technologies is equipped to handle both small and large IT projects. We have a versatile team of professionals with diverse skill sets who can adapt to project requirements of various scopes.",
            "Do you have any other questions?"],
            options: ["Can you provide examples of projects you have worked on?", "I want to ask other things.", "I have no further questions."],
        },

        "What types of IT services do you offer?": { 
            answer: ["We at Euodoó Technologies provide our customers with a wide variety of information technology services and solutions to cater to their individual requirements. For more information about what we do, please see this link: <a id='careerlink' target='_blank' href='/services' style='color:black;text-decoration: underline;'>Euodoo Services</a>",
            "Do you have any other questions?"],
            options: ["Can you provide examples of projects you have worked on?", "I want to ask other things.", "I have no further questions."],
        },

        "Can you provide examples of projects you have worked on?": { 
            answer: ["We at Euodoó Technologies have successfully completed projects. We take pleasure in the fact that we were the first business to incorporate distance-based fare collection for the modernization of the PUVMP project. We also teamed up with TBP, and with the aid of our event management system, they were able to effectively organize a number of events throughout the Philippines. For more information, please see this link: <br/> <a id='careerlink' target='_blank' href='/news' style='color:black;text-decoration: underline;'>News and Updates</a>",
            "Do you have any other questions?"],
            options: ["I want to ask other things.", "I have no further questions."],
        },

        "Do you have any certifications or partnerships with major technology vendors?": { 
            answer: ["With top technology vendors, Euodoó Technologies is proud to have partnerships and certifications. We have excellent partnerships and industry-recognized certifications, including VAPT competence, Duns & Bradstreet accreditation, and IPO certification.",
            "Do you have any other questions?"],
            options: ["I want to ask other things.", "I have no further questions."],
        },

        "I have a question about the company's data security.":{
            answer: ["We'd be happy to answer your concerns. To ensure an approriate response, please specify your question."],
            options: ["How do you ensure the security of client data and sensitive information?", 
            "How do you handle data backup and disaster recovery planning?",
            "I want to ask other things.",
            ]
        },

        "How do you ensure the security of client data and sensitive information?": { 
            answer: ["At Euodoó Technologies, we understand the critical importance of data security and the protection of sensitive client information. We employ several measures and best practices to ensure the security and confidentiality of client data.",
            "Do you have any other questions?"],
            options: ["I want to ask other things.", "I have no further questions."],
        },

        "How do you handle data backup and disaster recovery planning?": { 
            answer: ["We follow industry standards and best practices for data security, maintain a secure infrastructure with strong firewalls, intrusion detection systems, and modern encryption algorithms, to protect data while it is in transit and at rest, we use encryption techniques. In order to inform our staff of the significance of data security and privacy, we regularly conduct training and awareness programs. This includes encrypting sensitive data housed in databases or on cloud platforms.",
            "Do you have any other questions?"],
            options: ["I want to ask other things.", "I have no further questions."],
        },

        "What is your pricing structure for IT services?": { 
            answer: ["You can go see the services we provided first in <a id='careerlink' target='_blank' href='/services' style='color:black;text-decoration: underline;'>Euodoó Services</a>. If you're interested, you can schedule a meeting with us or reserve a demo  through this link <a id='careerlink' target='_blank' href='/contactus' style='color:black;text-decoration: underline;'>Get in Touch</a> so we can assess your requirements and provide an estimated cost.",
            "Do you have any other questions?"],
            options: ["I want to ask other things.", "I have no further questions."],
        },

        "Where can I learn more about opportunities with Euodoo and how should I apply?": { 
            answer: ["We encourage you to submit an application to work for our company if you have a strong work ethic, are able to adapt to any circumstance with ease, are not frightened of challenges, and want to work in a workplace where everyone regards one another with respect. Go to the <a id='careerlink' target='_blank' href='/careers' style='color:black;text-decoration: underline;'>Euodoó Careers</a> link for further details.",
            "Do you have any other questions?"],
            options: ["I want to ask other things.", "I have no further questions."],
        },

        ending_convo: {
            answer: ["Thank you for connecting with us. Hope our answers were helpful."],
            renew: ["Click here to start a new converstion."]
        }

    }

    function openForm(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
            e.persist();
        }
        document.getElementById("myForm").style.display = "block";
        document.getElementById("badge").style.visibility = 'hidden';
        document.getElementById("close-pop").style.display = 'none';
        setExpiry("pop", true, 12*60*60*1000);
        getLogin();    
    }

    function restart() {
        const chatBody = document.querySelector('.chat-body');
        chatBody.innerHTML = '';
        sessionStorage.removeItem("chat");
        startChat();
    }

    function getLogin() {
        const chatBody = document.querySelector('.chat-body');
        document.querySelector(".loading").style.display = "none";
        var form = document.createElement("form");

        if((chatBody.innerHTML==="")){
        var title = document.createElement("p");
        title.setAttribute("class", "chat-tle");
        title.innerHTML = "euodoó "
        chatBody.append(title);
        
        var elm= document.createElement("p");
        elm.setAttribute("id","answerspan");
        var sp=data.chatinit.signwelcome;
        elm.innerHTML=sp;

        chatBody.append(elm);

        // email
        var ediv = document.createElement("div");
        var email = document.createElement("input");
        email.setAttribute("type", "email");
        email.setAttribute("id", "email");
        email.setAttribute("class", "ein-field");
        email.setAttribute("placeholder", "Email");
        email.required = true;  
        ediv.setAttribute("id", "ediv");
        ediv.append(email);
        form.append(ediv);

        var div = document.createElement("div");
        div.setAttribute("class", "sign-div");

        //name
        var ndiv = document.createElement("div");
        var name = document.createElement("input");
        
        name.setAttribute("type", "text");
        name.setAttribute("id", "name");
        name.setAttribute("placeholder", "Name");
        name.setAttribute("class", "nin-field");
        name.required = true;   
        form.append(name);
        ndiv.setAttribute("id", "ndiv");
        ndiv.append(name);
        div.append(ndiv);
        form.append(div);

        //addeventlistener
        name.addEventListener("input", getinput);
        email.addEventListener("input", getinput);

        //submit
        var submit= document.createElement('button');
        submit.innerHTML="<img width='30' height='30' src='https://img.icons8.com/ios-glyphs/30/4a124a/sent.png' alt='sent'/>";
        submit.setAttribute("type", "submit");
        submit.setAttribute("value", "send");
        submit.setAttribute("class", "submit-chat");
        div.append(submit);
        form.append(div);    

        form.addEventListener("submit", addchatuser);
        form.addEventListener("submit", startChat);

        chatBody.append(form);
                

        }
        

    }

    function startChat() {
        const chatBody = document.querySelector('.chat-body');
        chatBody.innerHTML = '';
        document.querySelector(".loading").style.display = "none";
        document.getElementById("form-header").style.display = "flex";
        document.getElementById("sectionChat").style.display = "none";

        
        if((chatBody.innerHTML==="")){
            start(data.chatinit.welcome, data.chatinit.options);
        }
            
    }

    const yes = (e)=>{
        if (e && e.preventDefault) {
            e.preventDefault();
            e.persist();
        }
        document.querySelector(".loading").style.display = "block";
        setTimeout(() => {
            document.querySelector(".loading").style.display = "none";
            start(data.chatinit.welcome, data.chatinit.options);
        }, 1800);
    }


    function start(welcome, options){
        const chatBody = document.querySelector('.chat-body');

        var title = document.createElement("p");
        title.setAttribute("class", "chat-tle");
        title.innerHTML = "euodoó "
        chatBody.append(title);
        
        var elm= document.createElement("p");
        elm.setAttribute("id","answerspan");
        var sp=welcome;
        elm.innerHTML=sp;

        chatBody.append(elm);

        for(var i=0;i<options.length;i++){
            var opt= document.createElement('button');
            var inp= options[i];
            opt.innerHTML=inp;
            opt.setAttribute("class","btnq");
            opt.addEventListener("click", handleOpt);
            chatBody.appendChild(opt);

        }

        handleScroll();       
    }

    function handleOpt(event){
        event.preventDefault();
            const chatBody = document.querySelector('.chat-body');

            var today = new Date();

            var str= this.innerText;

            document.querySelectorAll(".btnq").forEach(el=>{
                el.remove();
            })

            //prints answer of the user
            const div = document.createElement("div");

            var elm= document.createElement("p");
            elm.setAttribute("id","userspan");
            var sp=this.innerText;
            elm.innerHTML= sp;
            div.append(elm);
            div.setAttribute("class","userDiv");

            var time= document.createElement("p");
            time.innerHTML = today.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            time.setAttribute("class", "time");
            chatBody.append(div);
            chatBody.append(time);

            document.querySelector(".loading").style.display = "inline-block";

            if (str === "I want to ask other things."){
                yes();
            }
            else if (str === "I have no further questions."){
                no();
            }
            else{
                const s = data[str].answer;
                setTimeout(() => {
                    var chat_title = document.createElement("div");
                    var title = document.createElement("p");
                    title.setAttribute("class", "chat-tle");
                    title.innerHTML = "euodoó"
                    chat_title.append(title);
    
                    chat_title.setAttribute("class", "chatDiv");
                    chatBody.append(chat_title);
                }, 2000);

                for(let i=0;i<s.length;i++){
                    setTimeout(() => {
                        document.querySelector(".loading").style.display = "none";
                        handleResult(s[i]);
                    }, 2000);
                }
                setTimeout(() => {
                    endConvo(data[str].options);
                }, 2000);
            }
    
    }

    function handleResult(answers){
        const div = document.createElement("div");
        const chatBody = document.querySelector('.chat-body');

        var elm= document.createElement("p");
        elm.innerHTML= answers;
        elm.setAttribute("id","answerspan");
        div.append(elm);

        div.setAttribute("class","answerDiv");
        chatBody.append(div);

        handleScroll();
    }

    function endConvo(options){
        const chatBody = document.querySelector('.chat-body');

        for(var i=0;i<options.length;i++){
            var opt= document.createElement('button');
            var inp= '<div>'+ options[i]+'</div>';
            opt.innerHTML=inp;
            opt.setAttribute("class","btnq");
            opt.addEventListener("click", handleOpt);
            chatBody.appendChild(opt);
        }

        handleScroll();

    }

    function showEnding(ending){
        const chatBody = document.querySelector('.chat-body');
        const div = document.createElement("div");

        var chat_title = document.createElement("div");
        var title = document.createElement("p");
        title.setAttribute("class", "chat-tle");
        title.innerHTML = "euodoó"
        chat_title.append(title);

        chat_title.setAttribute("class", "chatDiv");
        chatBody.append(chat_title);

        var elm= document.createElement("p");
        elm.setAttribute("id","answerspan");
        var sp=ending;
        elm.innerHTML= sp;
        div.append(elm);

        div.setAttribute("class","answerDiv");
        chatBody.append(div);

        handleScroll();
    }

    function renewChat(r){
        const chatBody = document.querySelector('.chat-body');
        const div = document.createElement("div");

        var renewChat = document.createElement('button');
        var rChat = r;
        renewChat.innerText = rChat;
        renewChat.setAttribute("id", "link");
        renewChat.style.marginTop = "20px";
        renewChat.addEventListener("click", restart);
        div.append(renewChat);

        div.setAttribute("class","renewDiv_2");
        chatBody.append(div);

        handleScroll();
    }

    function handleScroll(){
        const chatBody = document.querySelector('.form-body');
        chatBody.scrollTop = chatBody.scrollHeight;
        s_setExpiry('chat', chatBody.innerHTML, 5*60*60*1000);
    }

    return(
        <div>  
            <div className="o-button">
                <div className="chat-pop" id="close-pop">
                    <img className="close" width="17" height="17" src="https://img.icons8.com/ios-filled/50/4a124a/delete-sign--v1.png" alt="delete-sign--v1" onClick={closePop}/>
                    <p className="chat-tle">euodoó</p>
                    <p className="chat-message">Hi! Welcome to Euodoó Tech. <br/>We're glad you visit our page.</p>
                </div>  
                <img className="open-button" src={logo}onClick={openForm}/>
                <p id="badge">1</p>
            </div>
            <div className="chat-popup" id="myForm">
                <div className="form-container" id="chatform">

                    <header className="form-header" id="form-header">
                        <img className="chatlogo" width="90" height="30" src={chatlogo} alt="logo" />
                        <img className="cancel" width="20" height="20" src="https://img.icons8.com/ios-filled/50/4a124a/delete-sign--v1.png" alt="delete-sign--v1" onClick={closeForm}/>
                    </header>
                    <div className="sectionChat" id="sectionChat">
                        <img className="s-cancel" width="20" height="20" src="https://img.icons8.com/ios-filled/50/4a124a/delete-sign--v1.png" alt="delete-sign--v1" onClick={closeForm}/>
                        <img className="s-chatlogo" width="110" height="35" src={chatlogo} alt="logo" />
                    </div>       
                    <div className="form-body" id="chat-form">
                        <div className="chat-body" id="chat-body">
                        </div>
                        <div className="loading hide">
                            <div className="circle circle-1"></div>
                            <div className="circle circle-2"></div>
                            <div className="circle circle-3"></div>
                        </div>
                    </div>
                    {/* <button type="button" className="btn cancel" id="cancel" onClick={closeForm}>Close</button> */}
                </div>
            </div>

        </div>
    );


}

export default Chattest;
