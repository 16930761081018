import logo from "../assets/logo/Euodoo.png"
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import $ from "jquery";
 const Header = ()=>{








    // -----------------------------hide navbar-----------------------------------------------

    $(function() {
        $(document).click(function (event) {
            var clicked_class=event.target.getAttribute("class");
            var clickover = $(event.target);
            var _opened =
            $(".navbar-collapse").hasClass("navbar-collapse collapse show");
            if (_opened === true && clickover.closest('.navbar').length === 0) {
                $(".navbar-toggler").click();
            }
            else if(clicked_class==="nav-link" )
            {
                $(".navbar-toggler").click();
            }
            else if(clicked_class==="dropdown-item")
            {
                $(".navbar-toggler").click();
            }
        });
    });



    return(
        <div className='head'>
            <nav className='navigation fixed-top fw-bold text  navbar navbar-expand-lg bg-body-tertiary' >
                <div className="container-fluid">
                    <HashLink className="navbar-brand" smooth to='/#home'>
                        <img src={logo}  height="35px" width="100px" alt="euodoo logo"/>
                    </HashLink>
                    <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className=" justify-content-end collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <HashLink className="nav-link" smooth to='/#home'> HOME</HashLink>
                            </li>
                            <li className='nav-item'>
                                <HashLink className="nav-link" smooth to='/#aboutus' >OUR PROFILE </HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink className="nav-link" smooth to='/#services' >SERVICES</HashLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    MORE
                                </a>
                                <ul id="menu" className="dropdown-menu">
                                    <li><Link className="dropdown-item" to="/news">News and Updates</Link></li>
                                    <li><Link className="dropdown-item" to="/careers">Careers</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>

    )
}
export default Header;