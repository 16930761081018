export const SERVICE_ID = "service_b77495g"
export const TEMPLATE_ID = "template_d0zi80oo"
export const PUBLIC_KEY  = "wCzRPrKRG7w3cdxmS"
export const SERVER = "https://euodooserver.onrender.com/"
// export const SERVER = "http://localhost:4000/"
export const ADMINKEY = "hfyr65nrjj769d1m3dsdhr"
export const SCHEDULEKEY = "fgdhyhrthrdhthello1"
export const EMAILTEST = "vivian614miranda@gmail.com"
export const EMAILFROM = "euodoocustomercare@gmail.com"
export const EMAILTO = "customercare@euodoo.com.ph"
