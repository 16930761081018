import enterprise from "../assets/moreservices/enterprise.jpg";
import planning from "../assets/moreservices/planning.jpg";
import cloud from "../assets/moreservices/cloud.jpg";
import antimoney from "../assets/moreservices/antimoney.jpg";
import gov from "../assets/moreservices/gov.jpg";
import hris from "../assets/moreservices/hris.jpg";
import customer from "../assets/moreservices/customer.jpg";
import customized from "../assets/moreservices/customized.jpg";
import loans from "../assets/moreservices/loans.png";
import automate from "../assets/moreservices/automated.png";
import pia from "../assets/moreservices/privacy.jpg";
import vapt from "../assets/moreservices/vapt.jpeg";
import hospital from "../assets/moreservices/hospital.png";
import integrated from "../assets/moreservices/integratedsch.jpg";
import web from "../assets/moreservices/webdev.jpg";
import ltfrb from "../assets/moreservices/gps.jpg";
import sap from "../assets/moreservices/sap.jpg";
import document from "../assets/moreservices/dataconv.jpg";
import structured from "../assets/moreservices/struc.png";
import itser from "../assets/moreservices/itservices.jpeg";
import eventm from "../assets/moreservices/eventm.png";

// --------------------logo------------------------------------
import ecm from "../assets/services/services logo/ecm.png";
import erp from "../assets/services/services logo/erp.png";
import cloudbased from "../assets/services/services logo/cloudbasedschool.png";
import amla from "../assets/services/services logo/amla.png";
import grc from "../assets/services/services logo/grc.png";
import hrisl from "../assets/services/services logo/hris.png";
import crm  from "../assets/services/services logo/crm.png";
import customizedsystem from "../assets/services/services logo/customizedsystem.png";
import loansl from "../assets/services/services logo/loan.png";
import afcs from "../assets/services/services logo/afcs.png";
import pial from "../assets/services/services logo/pia.png";
import vaptl from "../assets/services/services logo/vapt.png";
import hmis from "../assets/services/services logo/hmis.png";
import icsms from "../assets/services/services logo/icsms.png";
import webl from "../assets/services/services logo/webdev.png";
import ltfrbl from "../assets/services/services logo/ltfrb.png";
import sapl from "../assets/services/services logo/sap.png";
import doc from "../assets/services/services logo/doc-.png";
import struc from "../assets/services/services logo/struc.png";
import otherit from "../assets/services/services logo/others.png";
import event from "../assets/services/services logo/event management.png";

import { Link } from "react-router-dom";


export const services = [
    {
        id:"event",
        logo: event,
        title:"Onsite, Virtual, and Hybrid Event Management System",
        img: eventm,
        content: [
            `
            Euodoo Technologies, Inc. has event management system that has  a different features such as registration process, printing of badges, listing sessions, appointments, and many
            more functions for onsite, virtual, and hybrid event.
        `,
        <div className="mt-4">
        <Link to="/eventmanagement" type="button" className="serviceBtn m-0 p-0 "><b>See more</b></Link>
        </div>

        ],
       
        
    
    },

    {
        id:"ltfrb",
        logo: ltfrbl,
        title:"LTFRB-Accredited GPS Tracking System",
        img: ltfrb,
        content: `
        The LTFRB expects your GPS trackers to be online all the time. Problem is sometimes the network signals (Globe or Smart) are weak. We can help because we are the only LTFRB accredited GPS supplier in the Philippines that has GPS trackers working with both Globe and Smart networks at the same time for maximum coverage and reliability. This ensures you have the best experience possible with your GPS trackers, regardless of where you are in the Philippines.
        `
    },
    {
        id:"integrated",
        logo: cloudbased,
        title:"Integrated Cloud-based School Management System",
        img: cloud,
        content: `
        School management system refers to any kind of platform or application that mostly takes over the executive and management-related processes school. This means that daily routine, repetitive tasks that were originally the purview of human administrators are now managed by the school ERP system. It also takes over the organizational needs of your school in a fast, effective and efficient way. A cloud-based school management system is one that runs on cloud technology, instead of being local. This means that all data is stored in “the cloud”, which can be accessed from anywhere and at any time by the users.


        `
    },
    {
        id:"lms",
        logo: loansl,
        title:"Loans Management System",
        img: loans,
        content: `
        Loans Management Systems can help your sales team make the process of loan from Origination, Qualification, Loan Accounts Creation, and Payments simpler with the wonders of automation.
        `
    },
    {
        id:"amla",
        logo: amla,
        title:"Anti-Money Laundering (AMLA) system",
        img: antimoney,
        content: `
        Anti-money laundering (AML) software is a type of computer program used by financial institutions to analyze customer data and detect suspicious transactions.

Anti-laundering systems filter customer data, classify it according to level of suspicion and inspect it for anomalies. Such anomalies would include any sudden and substantial increase in funds or a large withdrawal.
        `
    },

    {
        id:"crm",
        logo: crm,
        title:"Customer Relationship Management (CRM)",
        img: customer,
        content: `
        Customer relationship management (CRM) is a technology for managing all your company’s relationships and interactions with customers and potential customers. The goal is simple: Improve business relationships. A CRM system helps companies stay connected to customers, streamline processes, and improve profitability.

When people talk about CRM, they usually refer to a CRM system or platform, a tool that helps with contact management, sales management, productivity, and more.

CRM software helps you focus on your organisation’s relationships with individual people — including customers, service users, colleagues, or suppliers — throughout your lifecycle with them, including finding new customers, winning their business, and providing support and additional services throughout the relationship.

With a CRM solution, the sales and marketing team can track and follow a customer’s interaction journey with your business. This can enhance the customer journey and experience by refining each customer touchpoint.
        `
    },
    {
        id:"hris",
        logo: hrisl,
        title:"Human Resource Information System (HRIS)",
        img: hris,
        content: `
        A human resources information system (HRIS) is a software solution that maintains, manages, and processes detailed employee information and human resources-related policies and procedures. As an interactive system of information management, the HRIS standardizes human resources (HR) tasks and processes while facilitating accurate record keeping and reporting.

Essentially, an HRIS is a “two-way street” in which information about employees is delivered into the organization and, conversely, back out to employees. By eliminating paper-based and manual HR-related processes, an HRIS offers more seamless, streamlined, and efficient interactions between employees and the companies they work for while freeing HR professionals to perform more strategic and high-value work.
        `
    },
    {
        id:"customized",
        logo: customizedsystem,
        title:"Customized System and Application Development",
        img: customized,
        content: `
        Custom software development is usually performed by in-house development teams or outsourced to a third-party. The same processes and methodologies apply to custom software development as other types of software development. A custom project would move through the familiar steps of requirements gathering, code construction, testing and deployment and apply the same methodologies, like Agile, DevOps  or Rapid Application Development, as any other software project.

Efforts associated with custom software development include application customization, application modernization and application management. Application customization refers to modifying COTS applications to support individual requirements. Application modernization plays a critical role in maintaining the viability of a business’s custom software to meet evolving user and market demands. Application management makes software effective by supporting tasks like installation, updating, performance and availability optimization, and service desk functions.
        `
    },

    {
        id:"web",
        logo: webl,
        title:"Website Development",
        img: web,
        content: `
        Web development is the building and maintenance of websites; it’s the work that happens behind the scenes to make a website look great, work fast and perform well with a seamless user experience.

        Web developers, or ‘devs’, do this by using a variety of coding languages. The languages they use depends on the types of tasks they are preforming and the platforms on which they are working.
        
        Web development skills are in high demand worldwide and well paid too – making development a great career option. It is one of the easiest accessible higher paid fields as you do not need a traditional university degree to become qualified.
        
        The field of web development is generally broken down into front-end (the user-facing side) and back-end (the server side). Let’s delve into the details.
        `
    },
    {
        id:"hmis",
        logo: hmis,
        title:"Hospital Management and Information System (HMIS)",
        img: hospital,
        content: `
        HMIS is implemented in a hospital for managing patient care and related administrative functions.  It has the potential to improve the efficiency of overall system through automation and generates necessary reports for managing operations, performance, quality, planning, decision-making and reporting for projects.  One should be aware that merely implementing HMIS will not by itself bring out all the improvement that we expect. , it should be systematically planned, implemented, used and continually improved.  At Aravind Eye Hospitals, India HMIS was first implemented in 1991.  The system has since been continuously reviewed, updated and expanded with new features as needed and opportunities arose for automation and improvement.

        Use of the HMIS and the information that it provided helped Aravind enormously on all fronts – efficiency, quality, compliance, demand, etc. Recognizing this, in 2003 we re-wrote the application using then current technologies and started offering it to other eye hospitals who can be benefited by furthering their eye care services.  Currently, there are 150+ eye hospitals in India and neighbouring countries, which are using Aravind HMIS.  What we have learnt is that many of the hospitals do not use the HMIS beyond automation of transactions and daily reporting while some of the users use it extensively for continuous improvement.  Based on the experience of working with these hospitals, the details of good practices for successful implementation and adoption of HMIS are shared.
        `
    },

    {
        id:"vapt",
        logo: vaptl,
        title:"Vulnerability and Penetration Testing (VAPT) services",
        img: vapt,
        content: `
        Vulnerability Assessment and Penetration Testing (VAPT) is a term used to describe security testing that is designed to identify and help address cyber security vulnerabilities. The meaning of VAPT can vary from one geographical region to another, either as a bracket for multiple distinct services, or a single, combined offering. VAPT as a whole could include anything from automated vulnerability assessments to human-led penetration testing and red team operations.
        `
    },
    {
        id:"afcs",
        logo: afcs,
        title:"Automated Fare Collection System (AFCS)",
        img: automate,
        content: `
        An automated fare collection (AFC) system is the collection of components that automate the ticketing system of a public transportation network - an automated version of manual fare collection. An AFC system is usually the basis for integrated ticketing.
        `
    },
    {
        id:"icms",
        logo: icsms,
        title:"Integrated Cloud School Management System (ICSMS)",
        img: integrated,
        content: `
        School Management Systems are tools designed to help institutions offering K12 education. You can use school management software to manage your school’s daily administrative and academic operations seamlessly from a remote location. The software will enable paperless and significantly smoother management of processes like admission, online fees submission, attendance, online class, online examination, etc.

The section below presents an all-inclusive guide to help you in comprehending the working procedure of an efficient school management system and its benefits. Reading through the detailed guide will make it easier for you to choose the best school management system.
        `
    },

    {
        id:"scs",
        logo: struc,
        title:"Structured cabling services",
        img: structured,
        content: `
        A structured cabling system is a complete system of cabling and associated hardware, which provides a comprehensive telecommunications infrastructure. This infrastructure serves a wide range of uses, such as to provide telephone service or transmit data through a computer network. It should not be device dependent.
        `
    },
    {
        id:"ecm",
        logo: ecm,
        title: "Enterprise Content Management (ECM)",
        img: enterprise,
        content: `
        Enterprise content management is an umbrella term for the technology, strategy and method used to capture, manage, access, integrate, measure and store information.

        The value of today's ECM solution goes beyond simple scan, store and retrieve solutions to encompass all the platform's capabilities and strengths.

        Traditionally, ECM was primarily comprised of transactional content management, a system of record for managing process-related documents. But transactional content management, often associated with business processes that help drive actions and decisions, is just part of ECM and another term under its umbrella.

        First-generation enterprise content management solutions no longer fully reflect the way users create, use and interact with information, which is why the term has evolved into content services. A successful content services approach helps you tackle changes in technology and the way we work, and combats information silos by delivering the right information to the right people at the right time.
                `
    },
    {
        id:"dcs",
        logo: doc,
        title:"Document conversion services (Outsourced scanning)",
        img: document,
        content: `
        The main goal of a document conversion service is to help businesses build a faster, more efficient way of storing, searching for, and retrieving information.

No matter what industry you are in, the benefits of going paperless are endless. Moving away from inefficient paper processes provides an immediate boost in productivity.
        `
    },
    {
        id:"grc",
        logo: grc,
        title:"Governance, Risk and Compliance (GRC) management tools",
        img: gov,
        content: `
        GRC tools are software applications that businesses can use to manage policies, assess risk, control user access, and streamline compliance. You might use some of the following GRC tools to integrate business processes, reduce costs, and improve efficiency. 
        `
    },
    {
        id:"otheritserve",
        logo: otherit,
        title:"Other IT Services (CCTV, PABX, PC servers and workstations)",
        img: itser,
        content: `
        IT services refers to the application of business and technical expertise to enable organizations in the creation, management and optimization of or access to information and business processes.

The IT services market can be segmented by the type of skills that are employed to deliver the service (design, build, run). There are also different categories of service: business process services, application services and infrastructure services.

If these services are outsourced, they are referred to as business process outsourcing (BPO), applications outsourcing (AO) and infrastructure outsourcing.
        `
    },
    {
        id:"sap",
        logo: sapl,
        title:`SAP Services`,
        img: sap,
        content: `
        (S/4 HANA, ABAP programming, BASIS administration, Functional and Technical 
        Consultancy and Implementation services).
        Compete in today’s business environment with a database solution that accelerates real-time, data-driven decisions. If you’re a business struggling to handle your growing database, SAP HANA can solve this issue because it can store and retrieve your data faster compared to other applications – allowing you to perform all transactions and analytical operations from a single system.
        `
    },
    {
        id:"pia",
        logo: pial,
        title:"Privacy Impact Assessment (PIA) services",
        img: pia,
        content: `
        A Privacy Impact Assessment (PIA) is an instrument for assessing the potential impacts on privacy of
        a process, information system, program, software module, device or other initiative which processes
        personal information and in consultation with stakeholders, for taking actions as necessary to treat
        privacy risk. A PIA report may include documentation about measures taken for risk treatment, for
        example, measures arising from the use of the information security management system (ISMS) in
        ISO/IEC 27001. 
        `
    },
    {
        id:"erp",
        logo: erp,
        title: "Enterprise Resource Planning (ERP)",
        img: planning,
        content: `
        Enterprise resource planning (ERP) refers to a type of software that organizations use to manage day-to-day business activities such as accounting, procurement, project management, risk management and compliance, and supply chain operations. A complete ERP suite also includes enterprise performance management, software that helps plan, budget, predict, and report on an organization’s financial results.

        ERP systems tie together a multitude of business processes and enable the flow of data between them. By collecting an organization’s shared transactional data from multiple sources, ERP systems eliminate data duplication and provide data integrity with a single source of truth.

        Today, ERP systems are critical for managing thousands of businesses of all sizes and in all industries. To these companies, ERP is as indispensable as the electricity that keeps the lights on.
                `
    }
]