import { useEffect } from "react";
import jobs from "../data/job.js";
const Career = ()=>{

    useEffect(() => {
        const page = document.getElementById('career');
        page.scrollIntoView({behavior:'smooth',block:'start'});
    });

    return(
        <div id="career" className="container">
            <h2 className="pb-5 text-uppercase text-center teamtext aboutusourcompanyprofile">
                Be part of our team</h2>
                
                {
                    jobs.map((job,index)=>{
                        return(
                            <div key={index} className="row pb-5 m-0">
                                <div className="col-12 col-md-5 col-lg-5">
                                    <img src={job.img} className="card-img-top pb-5 mb-4" alt="job"/>
                                </div>
                                <div className="col-12 col-md-1 col-lg-1">
                                </div>
                                <div className="col-12 col-md-6 col-lg-6" style={{"textAlign": "justify"}}>
                                    <h5 className="card-title pb-4">{job.title}</h5>
                                    <b className="card-text">DESCRIPTION:</b><br/>{job.description}<br/> 
                                    <br/><b  className="card-text">ROLES:</b>
                                    
                                    {job.roles.map((roles,index)=>{
                                        return(
                                            <ul className = "pt-3" key={index} style={{"listStyleType": "none"}}>
                                                <li>{roles}</li>
                                            </ul>
                                        )
                                    })}
                                     <b className="card-text">QUALIFICATIONS:</b><br/>
                                    {job.qualifications.map((qualifications,index)=>{
                                        return(
                                            <ul className = "" key={index} style={{"listStyleType": "none"}}>
                                                <li>{qualifications}</li>
                                            </ul>
                                        )
                                    })}
                                    <button type="button" ><b>{job.link}</b></button>
                                </div>
                                
                            </div>
                        
                        )
                        
                    })
                }
           
  
        </div>
    )
}

export default Career;