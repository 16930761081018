import { useEffect,useState } from "react";
import arrowup from "../../assets/arrowup.png";

import Articles from "../../data/article.js";
import { useParams } from "react-router-dom";
const Phcvs = ()=>{
    const [phcvsarticle, setphcvsarticle] = useState({});
    let { articleid } = useParams()

    useEffect(() => {
        const page = document.getElementById('c_showtitle');
        page.scrollIntoView({behavior:'smooth',block:'start'});

        const backToTopButton = document.querySelector(".back-to-top");
        const scrollfunc = ()=>{
            if(backToTopButton)
                if(window.scrollY>100){
                    backToTopButton.style.display = "block";
                }else{  
                    backToTopButton.style.display = "none";
                }
        }
        window.addEventListener("scroll", scrollfunc);
        setphcvsarticle(Articles[articleid]);
        return () => {
            setphcvsarticle({});
        }
       
    },[]);
    return(
        <div id="c_show" className="container " style={{paddingTop:"100px"}}>
            <h1 className="pb-5 text-uppercase buildingtech" id="c_showtitle">
                {phcvsarticle.title}
            </h1>
            <div className="text-center ">
                <img src={phcvsarticle.image} className="fmspic w-75 mb-5"/>
            </div>
            <div className="text-center ">
                <p style={{textAlign:"justify", width:"75%", margin:"auto"}}>{phcvsarticle.intro}</p>
            </div>
            <div className="articledetail">
              {phcvsarticle.sections}
            </div>
            <div>
                <a className="back-to-top"  href="#c_showtitle" 
                style={{position: "fixed",bottom:"4%", right:"44%"}}>
                    <img id="back-to-top" src={arrowup} style={{height:"40px", width:"50px"}} className="" alt="arrowup"/> 
                </a>
            </div>
        </div>
    )

}
export default Phcvs;