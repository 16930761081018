// PM Jeepney
import pm_jeep from '../assets/news/launching/pm_jeepney_launching1.jpg';
import bus from '../assets/news/launching/bus.png';
import w_erap from '../assets/news/launching/w_erap.png';
import w_partners_1 from '../assets/news/launching/w_partners_1.png';
import w_partners from '../assets/news/launching/w_partners.jpg';

// Lucban Genesis
import lucban_1 from '../assets/news/lucban_genesis/lucban_1.jpg';
import lucban_2 from '../assets/news/lucban_genesis/lucban_2.jpg';
import lucban_3 from '../assets/news/lucban_genesis/lucban_3.jpg';
import lucban_4 from '../assets/news/lucban_genesis/lucban_4.jpg';

// Alabang
import alabang_1 from '../assets/news/alabang/alabang_1.jpg';
import alabang_2 from '../assets/news/alabang/alabang_2.jpg';
import alabang_3 from '../assets/news/alabang/alabang_3.jpg';

// Boracay
import boracay_1 from '../assets/news/boracay/boracay_1.jpg';
import boracay_2 from '../assets/news/boracay/boracay_2.jpg';
import boracay_3 from '../assets/news/boracay/boracay_3.jpg';

// Metro Comet
import launching_1 from '../assets/news/metro_comet/launching_1.jpg';
import launching_2 from '../assets/news/metro_comet/launching_2.jpg';
import launching_3 from '../assets/news/metro_comet/launching_3.jpg';
import launching_4 from '../assets/news/metro_comet/launching_4.jpg';

// Sampol Bulac
import sampol_1 from '../assets/news/sampol_bulac/sampol_1.jpg';
import sampol_2 from '../assets/news/sampol_bulac/sampol_2.jpg';
import sampol_3 from '../assets/news/sampol_bulac/sampol_3.jpg';

// PUV North Luzon Caravan
import caravan_north_luz_1 from '../assets/news/modern_puv_north_luz/caravan_north_luz_1.jpg';
import caravan_north_luz_2 from '../assets/news/modern_puv_north_luz/caravan_north_luz_2.jpg';
import caravan_north_luz_3 from '../assets/news/modern_puv_north_luz/caravan_north_luz_3.jpg';
import caravan_north_luz_4 from '../assets/news/modern_puv_north_luz/caravan_north_luz_4.jpg';

// PUV Mindanao Caravan
import modern_puv_mindanao_1 from '../assets/news/modern_puv_mindanao/modern_puv_mindanao_1.jpg';
import modern_puv_mindanao_2 from '../assets/news/modern_puv_mindanao/modern_puv_mindanao_2.jpg';
// import modern_puv_mindanao_3 from '../assets/news/modern_puv_mindanao/modern_puv_mindanao_3.jpg';
import modern_puv_mindanao_4 from '../assets/news/modern_puv_mindanao/modern_puv_mindanao_4.jpg';

// PUVMP TC Congress
import puvmp_1 from '../assets/news/puvmp/puvmp_1.jpg';
import puvmp_2 from '../assets/news/puvmp/puvmp_2.jpg';
import puvmp_3 from '../assets/news/puvmp/puvmp_3.jpg';
import puvmp_4 from '../assets/news/puvmp/puvmp_4.jpg';

// facebook live
import newslive from '../assets/news/videos/NewsLive.mp4';

// article
import newsvid from '../assets/news/videos/NewsVid.mp4';

const Act = [
    {

    },
    {

    },
    {

    },

    {
        
        id: 0,
        title: "PM Jeepney launching",
        description: "In October 29, 2018 at 10am in the morning, marked an important milestone... ",
        slug: "pm-jeepney-launching",
        gallery: [
            {
                file: w_erap,
                description: `
                In October 29, 2018 at 10am in the morning, marked an 
                important milestone in the implementation of the PUV Modernization 
                Program. The Pangkalahatang Sangguniang Manila and Suburbs Drivers 
                Association (Pasang Masda) launched their first batch of Modern 
                Jeepneys for Public Utility Vehicle Modernization Program (PUVMP), 
                a flagship program of the Duterte administration which envisions a 
                restructured, modern, well-managed and environmentally sustainable 
                transport sector where drivers and operators have stable, sufficient 
                and dignified livelihoods while commuters get to their destinations 
                quickly, safely and comfortably.
                This ceremony was led by PM National President Roberto “Ka-Obet Martin 
                and have attended by the honorable government officials, MMDA Chairman, 
                Danilo Lim, LTRFB Chairman Martin B. Delgra III, DOTr Assistant Secretary 
                Mark De leon, Board Member LTFRB Atty. Aileen Lourdes Lizada, 
                LTFRB Regional Director Zona Russet Tamayo, First District Quezon City Councilor, 
                Committee on Transportation, Hon. Oliviere T. Belmonte, and Manila City Mayor Hon. 
                Joseph “Erap” Estrada. This historical ceremony was also attended by the Landbank 
                of the Philippines President Alex V. Beunaventura, Mr. Hajime Koso, President, 
                Isuzu Philippines Mr. Raymond Jarina, President & CEO, Isuzu INTECO Group, Mr. 
                Conrad Almazora, President of Almazora Corporation, and Euodoo Technologies 
                President and CEO Mr. Julius P. Zapata.
                “This is a right path we are on” MMDA Chairman Danilo Lim said in his 
                short speech during the PM jeepney launching press conference at the 
                Luck Garden Restaurant, in QC. "Nothing is constant in this world, 
                especially in the generation we are in, everything changes. Even though 
                we are saying that Jeepneys are iconic, Jeepneys are part of our culture 
                and history, we need this change for a better life that we deserved 
                and awaits us. We need a better public transportation that provides 
                comfort and safety to the commuters, well-organized, and environmental 
                friendly. MMDA strongly supports this program, one of the reasons why 
                heavy traffic lingers everywhere is because of the old PUV’s. This 
                change is something that we truly need to move forward."
                `
            },
            {
                file: w_partners,
                description: `
                “I, 1000 times agree to MMDA Chairman Danilo Lim, this is the way to go 
                forward” said LTFRB Chairman Martin Delga III. We know that there are a lot 
                of issues and concerns, but nonetheless, this is the program that has been 
                ripen and smoothen by time, and we need to respond to it in a very positive 
                way, a firm way. Notwithstanding the criticisms, notwithstanding the 
                issues that were thrown upon all of us who are in full support in Jeepney 
                modernization, but we firmly move forward with this program. I’m very happy 
                that the group of Pasang Masda is very supportive of PUVMP, this project 
                has also opened the doors of opportunities for the downstream industry, 
                who are components to the PUVMP.

                All of us in the Transportation Committees, in MMDA, 
                LTFRB, and LTO, we are encouraging you to help us to lessen 
                the weight of our jobs in tracking and chasing the polluted vehicles 
                and old PUV’s that contributes hardship to the commuters and the society 
                due to traffic and pollution. We need a change, and we need to modernized 
                our public transportation system. Who will benefit from all of this the 
                most? it’s not just us in the government, not just the modern jeepney manufacturers, 
                and not just Pasang Masda and other transport group, but the commuters. 
                The commuters will benefit the most from this, commuters all throughout 
                the country that we are serving. LTFRB Chairman Martin Delgra reiterated, during his speech.
                `
            },
            {
                file: w_partners_1,
                description: `
                To those other transport groups that supports this program, you are all welcome to participate, we will just initiate things in due process. When we make a call for franchises, all of those who are interested, all of the transport groups, transport cooperatives, or even corporations who are willing to participate, you are very much welcome to come and join us in this crusade, to modernized our public transportation system. LTFRB Chairman said, as he ended his speech.

DOTr Usec Mark De Leon also weighs in, “I am very happy that we are here now, the route for Pasang Masda is here. This will attest that there is truly a Jeepney Modernization, that we are on our way to the biggest change in Public Transportation here in the Philippines. There’s a lot of talks that this program must be stopped, but we all stand together to make this campaign evident, no one can stop us but our President Rodrigo Duterte himself, because he is the one who started this campaign. President Duterte really want this modernization to take place, he believes that this is what our country need in terms of a better Public Transportation.”
                `
            },
            {
                file: bus,
                description: `
                “I agree with MMDA Chairman Danilo Lim”, DOTr Usec De Leon added, “that the answer to traffic is to provide a good public transportation, this is it. Most of the people tend to buy their own cars because there’s no option for a better transportation. We are always settling to something less, because that’s the only thing we have as of now is terms of public transportation, but nevertheless, change is happening.”

“In Taguig, Pateros, in our new routes, commuters there are waiting for the new Jeepneys to arrive, because they really want to ride an efficient and comfortable Modern Jeepneys. That’s what people are after in our present situation, and this is what we see ourselves in the future. This is what commuters want, and they don’t mind paying for additional one or two pesos for this. This is what we want to achieve for the commuters, and we are 100% sure that it will have a positive impact to our country and economy”. DOTr Asec Mark De Leon, as he ended his speech.

It is really good to know that the government is in full support in this campaign and they have displayed a firm, and enthusiastic stand to the PUVMP. Time for a dose of reality: change in society is absolutely vital if the countries are to realize the benefits it can give to the citizens, growth potential for the economy, to prosper and propel onto greater things which were once but a pipe dream.

The world changes every day. Society is constantly changing. If we fail to keep up with these constantly-moving targets, they lag behind, become stale and, ultimately, fail. Great leaders understand this, which is why they continually look for ways to evolve and improve. Euodoo Technologies, Inc. is in full swing of providing a better innovation and technologies that could help our society and economy in reaching greater heights for prosperity and success.


                `
            },
        ],
        thumbnail: pm_jeep,
        fileType: "jpg"
    },
    {
        id: 1,
        title: "Facebook live by Jacque Manabat",
        slug: "facebook-live-by-jacque-manabat",
        gallery: [
            {
                file: newslive,
                description: `
                 
                `
            } 
        ],
        thumbnail: newslive,
        fileType: "mp4",
    },
    {
        id: 2,
        title: "euodoó featured in News Articles",
        description: "Completing the modernized look and features of the new PUVs are the LED...",
        slug: "euodoó-featured-in-news-articles",
        gallery: [
            {
                file: newsvid,
                description: `
                Completing the modernized look and features of the new PUVs are the LED electronic signages for vehicle route information, fire extinguishers, CCTV, dashcam and back-up cameras, and the automatic fare collection provided by Euodoo Technologies Inc.
                Completing the modernized look and features of the new PUVs are the LED electronic signages for vehicle route information, fire extinguishers, CCTV, dashcam and backup cameras, and the automatic fare collection provided by Euodoo Technologies, Inc. These are accesories are geard for passengers' safety, entertainment, added convenience in fare collection and in compliance with LTFRB and DOTr regulations.
                `
            }
        ],
        thumbnail: newsvid,
        fileType: "mp4"
    },
    {
        id: 3,
        title: "Metro Comet TSC Launching Activities",
        description:"Office of Transport Cooperatives member Metro Comet recently inaugurated its...",
        slug: "metro-comet-tsc-launching-activities",
        gallery: [
            {
                file: launching_1,
                description: `
                Office of Transport Cooperatives member Metro Comet recently inaugurated its modern jeepney as part of its participation in the government's PUV Modernization Program. This signals the first time that Class 3 units will be used as PUVs in the country.
                Metro Comet Transport Service Cooperative recently launched its modern jeepney as part of its participation in the government's PUV Modernization Program. For the past two decades, Metro Comet had been operating with UV Express, with routes from Montalban to Cubao and terminals in different parts of the metro and neighboring areas.
                Now that various transport cooperatives have been gearing towards the use of more sustainable, more efficient, and more comfortable vehicles as modes of transportation, indeed the administration's PUV Modernization Program is inching closer to be fully implemented.
                `
            },
            {
                file: launching_2,
                description: `
                Attending the turnover ceremonies were IPC executives led by IPC Executive Vice President Shojiro Sakoda andIPC Sales Division Head Joseph Bautista, Rodriguez local government officials, and Metro Comet Transport Cooperative officers led by its Chairman, Mr. Ed Comia.
                The first batch of 15 modern PUVs is envisioned to replace the current UV Express fleet plying the Rodriguez to Cubao route. Mr. Bautista explained that, unlike the current UV Express vans, the Isuzu modern PUV is more spacious, safer, and more convenient to ride in.
                “Our modernized PUVs using the QKR 77 truck platform with body designed and built by Almazora can accommodate 23 seated passengers, with each seated passenger enjoying 400mm of width allowance, legroom of 650mm, and standing height of 1.75 meters. Commuters can now travel comfortably with enough legroom, headroom, and seat space.”
                `
            },
            {
                file: launching_3,
                description: `
                He added, “The vehicle is also equipped with features required by the PUVMP vehicle guidelines. The automatic fare collection system by Eudoo allows passengers to just tap their cards whenever they enter and exit. The vehicle’s GPS tracking system can now pinpoint every passenger’s point of entry and exit, so their fares will be based on the distance plotted by the GPS system. There are cameras installed in the vehicle; one facing the passengers, another facing the door, and then another installed at the dashboard facing traffic. The vehicle is also equipped with Wi-Fi which can accommodate online activities of all passengers andequipped with air condition by coolaire.“
                `
            },
            {
                file: launching_4,
                description: `
                In his speech before the official turnover ceremony, Mr. Sakoda declared: “It cannot be denied that Isuzu has already created a name for the Filipino drivers and operators as their reliable partner. Isuzu commits itself in providing functional and innovative transport systems not only for the welfare of Filipino businesses and families, but also for a greener and safer environment. Today, we are very proud to say that Isuzu not only achieved another milestone but also continued its legacy by having these Isuzu PUVs to run the public roads under the government’s PUV Modernization Program. With these Isuzu PUVs, we are truly confident that we will be able to provide a more comfortable and convenient ride to our public commuters.”
                `
            }
        ],
        thumbnail: launching_1,
        fileType: "jpg"
    },
    {
        id: 4,
        title: "Lucban Genesis TSMPC Launching Activities",
        description:'Lucban, the "Art Capital", home of the prestigious and colorful Pahiyas Festival...',
        slug: "lucban-genesis-tsmpc-launching-activities",
        gallery: [
            {
                file: lucban_1,
                description: `
                Lucban, the "Art Capital", home of the prestigious and colorful Pahiyas Festival, and a 2nd class municipality of province of Quezon, Philippines, is now among the most recent places to take advantage of the government’s PUV Modernization Program (PUVMP). 
                LTFRB Region IV-A Director Renwick Rutaquio presented the new design of Public Utility Jeepneys (PUJ) in compliance with the PUVMP guidelines. The PUVMP aims to provide safer, more convenient, environment-friendly, and modernized mode of public transportation to commuters.
                The key officials who attended the event were Lucban OIC Mayor Armando Abutal, Land Transportation Office District Officer Wilma De Castro, Office of Transportation Cooperatives Executive Director Eugene M. Pabualan, Development Bank of the Philippines Senior Vice President Jose Gabino Dimayuga, Cooperative Development Authority Regional Director Salvador Valeroso, Isuzu Philippines Corp. District Head in Sales Mr. Joseph Bautista, Isuzu Philippines Corp. Sales Department Head Mr. Mario Ojales, Isuzu Lucban General Manager Marcel Tolentino, and euodoó Technologies, Inc. Head in Sales and Marketing Mr. Richard D. Montealegre.
                `
            },
            {
                file: lucban_2,
                description: `
                Public transportation is an important contributing factor to urban sustainability. Effective transportation networks that incorporate public transit help lower a city’s per capita carbon footprint, and make cities more livable by easing commute and transportation needs and increasing accessibility.
                Isuzu’s Euro 4 engine, is the emission standards to limit the amount of harmful chemicals that spreads in the air. This seek to limit the vehicle’s toxic gas in hopes to attain a cleaner, breathable air. Sustainable Cities Institute names five principles of sustainability for municipal transportation: accessibility; affordability; connectivity; holistic transportation and land use planning; and planning with the environment in mind. 
                `
            },
            {
                file: lucban_3,
                description: `
                Partnered with Isuzu Philippines, euodoó technologies is the leading provider of CCTV, GPS, Wifi, Dashcam, Led Signage, LED Monitor, and the very first Distance based Automated Fare Collection System (AFCS) that is thoroughly designed for Modernized PUV’s. Isuzu’s Modernized PUV’s is geared up with complete accessories that are compliant to the government’s requirement.  

                `
            },
            {
                file: lucban_4,
                description: `
                euodoó is tapping this historical opportunity in transportation industry of Lucban Quezon, by gearing up towards the continuous development of the state of the art technology, and providing improved and excellent service. Congratulations to Lucban Genesis Transport Service and Multipurpose Cooperative (LGTSMPC) for a successful and fruitful event.

                `
            }
        ],
        thumbnail: lucban_1,
        fileType: "jpg"
    },
    {
        id: 5,
        title: "Boracay Land Transport MPC Launching Activities",
        description: "Tourists as well as residents of the island resort of Boracay will not only get to...",
        slug: "boracay-land-transport-mpc-launching-activities",
        gallery: [
            {
                file: boracay_1,
                description: `
                Tourists as well as residents of the island resort of Boracay will not only get to enjoy one of the world’s best beaches, they will now also get to travel around this tropical paradise in utmost comfort, convenience, and safety on board the Isuzu modern public utility vehicles (MPUVs).
                These modernized PUVs will ply the main circumferential road that goes around the interior of the island, picking up and dropping off passengers at designated stops.
                `
            },
            {
                file: boracay_2,
                description: `
                Mr. Joseph Bautista, IPC Sales Division Head, described the turnover as happening at just the right time. “In the past few months, we have seen the earnest rehabilitation of Boracay Island; from its beaches toward the road network and infrastructure. And now that the island has just recently been re-opened to the public, we are very happy to have been a strategic part of this rehabilitation. With a cleaner, more organized Boracay, you will now also see Isuzu modernized PUVs servicing the island’s residents and tourists.”
                He added that Isuzu’s modern PUVs have also been designed and built in accordance to the objective of the Boracay rehabilitation efforts to make the island environmentally sustainable.
                “The modernized PUVs are assembled using the Isuzu QKR77 platform which has been modified for PUV use. These are powered by the 3.0-liter 4JH1-TC CRDI diesel engine that delivers 106 Ps of power with better fuel economy. The engine is also rated Euro IV compliant, so it produces cleaner emissions.”
                Mr. Yasuhiko Oyama, IPC Vice President for Sales, also lauded the Filipino mark of excellence in the construction and design of Isuzu’s modern PUV. “The Isuzu QKR PUV is truly a vehicle for Filipinos made by Filipinos. As they say in the native language, “Gawang Pilipino, para sa Pilipino.”
                `
            },
            {
                file: boracay_3,
                description: `
                The Isuzu modern PUV’s body has been designed and built by all-Filipino body builder and long-time partner of IPC, Almazora Motors Corporation. This Isuzu modern PUV can accommodate up to 30 passengers (seated and standing). The fully air-conditioned cabin by Coolaire means that all passengers can travel comfortably even during the hottest summers, with enough legroom, headroom, and seat space in a Class 2 PUV.
                The modern PUVs are also equipped with accessories as required by the Land Transportation Franchising and Regulatory Board (LTFRB), such as CCTVs and dashcam, GPS tracking system, and automated electronic fare collection system. The automatic doors also face the sidewalk for safe and convenient entry and exit of passengers.
                Mr. Oyama thanked BLTMPC for choosing Isuzu. “We thank the cooperative for trusting us in providing them their first fleet of modern PUVs. Undoubtedly, Isuzu has already created a name for Filipino drivers and operators as their reliable partner. We are very proud to say that Isuzu has not only achieved another milestone but has also continued its legacy by having these Isuzu PUVs to run in Boracay’s roads under the government’s PUV modernization program.”
                `
            }
        ],
        thumbnail: boracay_1,
        fileType: "jpg"
    },
    {
        id: 6,
        title: "Alabang Transport Launching Activities",
        description: "Isuzu Philippines Corporation (IPC), continues to support PUV modernization...",
        slug: "alabang-transport-launching-activities",
        gallery: [
            {
                file: alabang_1,
                description: `
                Isuzu Philippines Corporation (IPC), continues to support PUV modernization in the country. Just recently, the country's leading manufacturer specializing in trucks and light commercial vehicles, turned over a fleet of modern PUVs to the Alabang Transport Service Cooperative.
                On the 12th of July, the Alabang Transport Service Cooperative (ATSC) received 24 units of Class 2 Isuzu PUVs with bodies manufactured by Almazora and Centro at the  Muntinlupa-Cavite Expressway (MCX). The event was attended by IPC Executives, ATSC Officers, and Municipal Officials.
                
                `
            },
            {
                file: alabang_2,
                description: `
                "We are glad that there is a growing number of transport cooperatives like Alabang Transport Service Cooperative who trust our brand and is confident that Isuzu will be able to serve their mission to the community." said IPC President Hajime Koso.
                Alabang Transport Service Cooperative is one of the oldest and biggest cooperative in Muntinlupa, and it is evident that the cooperative grows steadily since its founding on year 1975.
                "As our cooperative grows, we are assured that through the newly acquired Isuzu PUVs, we are able to promote development of the transport service here in Alabang, improve the quality, and safety of the commuters, and to serve our mission to the community of Muntinlupa." said ATSC Chairman Bienvenido Barra.
                `
            },
            {
                file: alabang_3,
                description: `
                Isuzu PUVs are designed to deliver powerful driving performance, high durability, comfort, convenience, and good transport quality. All are equipped with air-conditioners by Coolaire, CCTV and dash camera, GPS tracking system, and automated electronic fare collection system. The PUVs are also equipped with PWD-friendly features for the convenience, safety, and comfort of Filipino commuters.
                Isuzu's continues to give transport solution to Filipino Commuters as their priority, and they seek to do this by providing safer and more environment-friendly transport options.
                `
            }
        ],
        thumbnail: alabang_1,
        fileType: "jpg"
    },
    {
        id: 7,
        title: "Sampol Bulac TSC Launching Activities",
        description: "In a formal ceremony held last July 26, Isuzu Philippines Corporation (IPC)...",
        slug: "sampol-bulac-tsc-launching-activities",
        gallery: [
            {
                file: sampol_1,
                description: `
                In a formal ceremony held last July 26, Isuzu Philippines Corporation (IPC) turned over 16 units of its modernized public utility vehicles (PUVs) to Sampol-Bulac-Santa Maria-Meycauayan -Transport Service Cooperative (SBSMM-TSC) at the Activity Center of the City Hall Annex in Barangay Sapang Palay Proper in San Jose del Monte City in Bulacan province.
                After an early morning motorcade showcasing the modern PUVs that began in Sampol, passing through Santa Maria, and ending at San Jose del Monte Activity Center, IPC executives, led by Executive Vice President Shojiro Sakoda, Department Head of Fleet Sales Mario Ojales, and officers of Isuzu Quezon Avenue turned over to SBSMM-TSC officers Chairperson Elizabeth D. Villacruz and Vice Chairperson German Manao Jr. 16 Isuzu PUVs.
                Witnessing the turnover were guests from the local and national governments, the Department of Transportation the Land Transportation and Franchising Regulatory Board (LTFRB), Office of Transport Cooperatives   Land Bank, Centro Manufacturing Corporation, CoolAire and Eudoo Technology.
                `
            },
            {
                file: sampol_2,
                description: `
                The modern Isuzu PUVs assembled using the Isuzu QKR77 platform with rear body designed and manufactured by Centro Manufacturing Corporation, are Class 2 PUVs fitted with air-conditioning system by CoolAire and a side-facing seat configuration.  The Isuzu QKR platform is assembled in the Philippines and is equipped with Euro IV-compliant 4JH1-TC diesel engine that delivers excellent fuel economy.
                The new PUV body is compliant with Philippine National Standards (PNS 2126:2017). The passenger cabin floor-to-ceiling height and gangway width provides more space for passengers to move easily inside. The passenger door is safely situated on the right-hand side, facing the sidewalk. Other design features of the body include panoramic windows and windshields, full-body insulation, automatic door closer, and provisions for persons with disabilities.
                For SBSMM-TSC, a well-established transport entity in Bulacan that operates over 600 jeepneys, its acquisition of Isuzu modern PUVs will not only provide world-class transport to its riding public but will also make possible for the cooperative to reap the numerous aftersales advantages of IPC’s vaunted “PUV-X” program.
                `
            },
            {
                file: sampol_3,
                description: `
                The “PUV-X” Program enumerates Isuzu’s 10-Point Advantage: 1) Isuzu’s proven reliability, durability and efficiency; 2) Experienced Body Builders; 3) Accredited Accessories Suppliers; 4) An unmatched 3 years or 150,000 kms warranty coverage; 5) Nationwide dealership network of 43 outlets and counting; 6) Expert Truck Executives (Truck Elites) to help customers; 7) Nationwide parts availability, competent and trained service technicians, technical assistance from Japanese and Filipino engineers, and Isuzu Service Medic vans for roadside assistance anytime; 8) Drivers and mechanics trainings; 9) Extensive fleet management system, and; 10) All-around customer assistance.
                The 16 modern PUVs that SBSMM-TSC is now using are the latest additions in support of the government’s efforts to modernize all PUVs by June 2020, as embodied in the PUV Modernization Program (PUVMP) that envisions the replacement of some 200,000 PUVs nationwide 15 years and older with new, safe and environmentally friendly vehicles.
                `
            }
        ],
        thumbnail: sampol_1,
        fileType: "jpg"
    },
    {
        id: 8,
        title: "Modern PUV Caravan North Luzon Phase",
        description:"Isuzu Philippines Corporation (IPC) showed its unwavering support for...",
        slug: "modern-puv-caravan-north-luzon-phase",
        gallery: [
            {
                file: caravan_north_luz_1,
                description: `
                Isuzu Philippines Corporation (IPC) showed its unwavering support for the country’s PUV Modernization program by joining the LTFRB Modern PUV Caravan 2019. The event is a multisectoral program aimed to promote the nationwide adoption of modernized PUVs for a cleaner, safer, more efficient, and more organized mass transport system.
                `
            },
            {
                file: caravan_north_luz_2,
                description: `
                The motorcade, composed of modernized PUVs from Isuzu and other manufacturers, had on board representatives from the participating sectors of government and various industries: The DOTr; the Land Transportation Franchising and Regulatory Bureau (LTFRB); the Land Transportation Office (LTO); Development Bank of the Philippines (DBP); Landbank; the Chamber of Automotive Manufacturers of the Philippines Inc (CAMPI); the Truck Manufacturers Association; the Association of Vehicle Importers and Distributors (AVID); the Automotive Body Manufacturers Association of the Philippines (ABMAP); the Electric Vehicles Association of the Philippines (EVAP); and the Philippine Parts Maker Association (PPMA).
                `
            },
            {
                file: caravan_north_luz_3,
                description: `
                IPC, for its part, brought in and showcased the brand-new Isuzu QKR77 and NLR77 light-duty trucks configured as modernized PUVs (BPS PNS compliant) with body developed by one of the country’s leading body builders, Almazora Motors Corporation, Centro Manufacturing Corporation, and Santa Rosa Motor Works Inc.
                `
            },
            {
                file: caravan_north_luz_4,
                description: `
                According to IPC President Hajime Koso, the Modern PUV Caravan 2019 provides a great opportunity for more Filipino commuters to be made more aware of the numerous benefits of riding in modern PUVs in their localities, and for transport cooperatives to gain easier access to acquiring such vehicles
                `
            }
        ],
        thumbnail: caravan_north_luz_1,
        fileType: "jpg"
    },
    {
        id: 9,
        title: "Modern PUV Caravan Mindanao Phase",
        description: "Showcasing the PUV Modernization Program down to the grassroots level, the...",
        slug: "modern-puv-caravan-mindanao-phase",
        gallery: [ 
            {
                file: modern_puv_mindanao_1,
                description: `
                Showcasing the PUV Modernization Program down to the grassroots level, the Department of Transportation (DOTr) and the Land Transportation Franchising and Regulatory Board (LTFRB) launched last July a nationwide Modern PUV Caravan that it has dubbed as “Modern PUV: ‘Arangkada ng Pagbabago Tungo sa Kaunlaran.’”
                `
            },
            {
                file: modern_puv_mindanao_2,
                description: `
                Completing the first part that covered North Luzon and Metro Manila, the Modern PUV Caravan is now in second phase and is set to visit nine cities in Mindanao. First of the Mindanao leg was in General Santos City followed by the next stop in Tagum City.
                `
            },
            {
                file: modern_puv_mindanao_4,
                description: `
                Part three of the Mindanao leg was held in Cotabato City which served as a major activity under the new Bangsamoro Autonomous Region of Muslim Mindanao (BARMM). Succeeding part of the Modern PUV Caravan in Mindanao will be held in Pagadian City, Cagayan de Oro City, Valencia City, Butuan City, Tandag City and finally Surigao City.
                `
            }
        ],
        thumbnail: modern_puv_mindanao_1,
        fileType: "jpg"
    },
    {
        id: 10,
        title: "PUVMP Activities TC Congress",
        description:"",
        slug: "puvmp-activities-tc-congress",
        gallery: [
            {
                file: puvmp_1,
                description: `
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquam ex 
                non nisi interdum, in dictum nisl ultricies. Maecenas id massa sit amet libero 
                dignissim porta. Integer vitae turpis faucibus, suscipit arcu in, vulputate nisi. 
                Vivamus sagittis tellus quis neque rutrum ornare. Aliquam fermentum, mi eget rutrum 
                luctus, ante velit porta enim, congue consectetur ex quam sed nisl. Nam non metus sit 
                amet augue sollicitudin pharetra sit amet mollis felis. Integer pretium turpis vitae 
                velit viverra, quis volutpat tortor ultrices. In neque erat, cursus in urna quis, 
                sodales elementum tellus. Cras ornare leo a justo condimentum faucibus.
                `
            },
            {
                file: puvmp_2,
                description: `
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquam ex 
                non nisi interdum, in dictum nisl ultricies. Maecenas id massa sit amet libero 
                dignissim porta. Integer vitae turpis faucibus, suscipit arcu in, vulputate nisi. 
                Vivamus sagittis tellus quis neque rutrum ornare. Aliquam fermentum, mi eget rutrum 
                luctus, ante velit porta enim, congue consectetur ex quam sed nisl. Nam non metus sit 
                amet augue sollicitudin pharetra sit amet mollis felis. Integer pretium turpis vitae 
                velit viverra, quis volutpat tortor ultrices. In neque erat, cursus in urna quis, 
                sodales elementum tellus. Cras ornare leo a justo condimentum faucibus.
                `
            },
            {
                file: puvmp_3,
                description: `
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquam ex 
                non nisi interdum, in dictum nisl ultricies. Maecenas id massa sit amet libero 
                dignissim porta. Integer vitae turpis faucibus, suscipit arcu in, vulputate nisi. 
                Vivamus sagittis tellus quis neque rutrum ornare. Aliquam fermentum, mi eget rutrum 
                luctus, ante velit porta enim, congue consectetur ex quam sed nisl. Nam non metus sit 
                amet augue sollicitudin pharetra sit amet mollis felis. Integer pretium turpis vitae 
                velit viverra, quis volutpat tortor ultrices. In neque erat, cursus in urna quis, 
                sodales elementum tellus. Cras ornare leo a justo condimentum faucibus.
                `
            },
            {
                file: puvmp_4,
                description: `
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquam ex 
                non nisi interdum, in dictum nisl ultricies. Maecenas id massa sit amet libero 
                dignissim porta. Integer vitae turpis faucibus, suscipit arcu in, vulputate nisi. 
                Vivamus sagittis tellus quis neque rutrum ornare. Aliquam fermentum, mi eget rutrum 
                luctus, ante velit porta enim, congue consectetur ex quam sed nisl. Nam non metus sit 
                amet augue sollicitudin pharetra sit amet mollis felis. Integer pretium turpis vitae 
                velit viverra, quis volutpat tortor ultrices. In neque erat, cursus in urna quis, 
                sodales elementum tellus. Cras ornare leo a justo condimentum faucibus.
                `
            }
        ],
        thumbnail: puvmp_1,
        fileType: "jpg"
    }
]

export default Act;