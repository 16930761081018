//client
import baliwag from "../assets/clients/baliwag_transit.png";
import homes from "../assets/clients/homes1.png";
import pjs from "../assets/clients/pjs1.png";
import pnoc from "../assets/clients/pnoc1.png";
import mdc from "../assets/clients/mdc1.png";
import pjssupport from "../assets/clients/pjs_support1.png";
import primer from "../assets/clients/primer1.png";
import tesda from "../assets/clients/tesda.png";
import tpb from "../assets/clients/tpb-logo1.png";
import ptc from "../assets/clients/ptc1.png";

const clients = [baliwag,homes,pjs, pnoc,mdc,pjssupport, primer,tesda,tpb,ptc];


export default clients;