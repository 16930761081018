// import service from "../assets/servicepic.png";
import jeep from "../assets/home/modern_jeep.png";
import { useEffect } from "react";
const Coverage = ()=>{

    useEffect(() => {
        const page = document.getElementById('coverage');
        page.scrollIntoView({behavior:'smooth',block:'start'});
    });
    return(
        <div id="coverage" className="container coverage">
            <h2 className=" text-uppercase text-center teamtext aboutusourcompanyprofile pb-5">euodoó Tech Full Package Coverage</h2>
            {/* <img src={vapt} className="p-4 card-img-top" alt="..."/> */}
            <div className="row">
                <div className="text-center col-12">
                    {/* <img src={service} className="p-4 card-img-top" alt="..."/> */}
                    <img src={jeep} className="p-4 card-img-top" alt="modern jeep"/>
                </div>
                <div className="col-12">
                    <div className="card p-3 mb-5" style={{textAlign:"justify"}}>
                        <div className="card-body">
                            <p className="card-text">Only Euodoó Technologies, Inc., offers 100% REAL end-to-end solution for Jeepney Modernization. From industrial grade accessories distance-based AFCS. Internet subscription, GPS software subscription, reloadable Bayadcards, training, after-sales support, loading stations, and to deployment of Bayadcard Representatives, assisting passengers and drivers. Other offers can 
                            be deceiving. It either uses substandard devices or with hidden recurring costs.</p>
                            <p className="card-text">Euodoó Technologies, Inc. is a ONE-STOP-SHOP. Our 
                            goal is ensure a worry-free launching, and actual operation for 
                            our partners, Manufacturers, Dealers, Banks, and Transport Groups.</p>
                            <b><p>We got everything covered! 
                                <br/> Here’s our FULL PACKAGE coverage:
                            </p></b>

                            <ol>
                                <li>Monthly subscription of GPS software</li>
                                <li>One-year Internet subscription, for WiFi router with SIM card</li>
                                <li>Monthly data subscription for AFCS</li>
                                <li>Kiosks for loading terminals of AFCS</li>
                                <li>Bayadcard representatives, assisting and training of passengers, drivers, and operators</li>
                                <li>5,000 free reloadable BYC cards for transport groups</li>
                                <li>Fund settlement and management with banks, once transport group is operational</li>
                                <li>ALL accessories are industrial type</li>
                                <li>After-sales service</li>
                                <li>No one else in this business can offer this kind of service. The ONLY end-to-end solution, 
                                    for P.U.V.M.P. Not to mention, still the only REAL distance-based AFCS.</li>
                            </ol>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>

    )
}

export default Coverage;