import logo from "../assets/logo/euodoo-white.png"
import { HashLink } from 'react-router-hash-link';

const Footer = ()=> {
    return(
        <div id="footer">
           <div className="row m-0 pt-5">
                <div className="col-12 col-lg-3 col-md-3 g-0">
                    <HashLink className="footer-brand" smooth to='/#home'>
                        <img src={logo} className="image-fluid" alt="euodoo logo"/>
                    </HashLink>
                </div>
                <div className="col-12 col-lg-3 col-md-3">
                    <p className="text-white "><b>Address</b></p>
                    <p className="text-white">
                       10/F One Global Place 25th Street cor. 5th Avenue
                        Bonifacio Global City, Taguig 1632 Philippines 
                    </p>
                </div>
                <div className="col-12 col-lg-3 col-md-3">
                    <p className="text-white"><b>Contacts</b></p>
                    {/* <p className="text-white">Email: customercare@euodoo.com.ph</p> */}
                    <div className="row g-0 pt-0 m-0">
                            <div className="col-2">
                                <p className="text-white">Email: <br/>
                                Tel: </p>
                            </div>
                            <div className="col">
                                <p className="text-white">customercare@euodoo.com.ph<br/>
                                    +632-7088-7101 <br/>
                                    +63-960-471-9671
                                </p>
                            </div>
                    </div>
                </div>
                <div className="links col-12 col-lg-3 col-md-3">
                    <p className="text-white"><b>Links</b></p>
                    <div className="row pb-4">
                        <div className="col">
                            <a target="_blank" rel="noreferrer" href='https://www.facebook.com/profile.php?id=100083241621066' className='flex items-center'>
                                <i  className="fa-brands fa-facebook-square "></i>
                            </a>
                        </div>
                        <div className="col">
                            <a target="_blank" rel="noreferrer" href='https://www.youtube.com/@euodootech' className='flex items-center'>
                                <i className="fa-brands fa-youtube text-2xl"></i>
                            </a>
                        </div>
                        <div className="col">
                            <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/company/euodoo-technologies-inc/mycompany/' className='flex items-center'>
                                <i className="fa-brands fa-linkedin text-2xl"></i>
                            </a>
                        </div>
                        <div className="col">
                            <a target="_blank" rel="noreferrer" href='https://www.instagram.com/euodootechinc/' className='flex items-center'>
                                <i className="fa-brands fa-instagram text-2xl"></i>
                            </a>
                        </div>
                        <div className="col">
                            <a target="_blank" rel="noreferrer" href='https://www.tiktok.com/@euodootechnologies?lang=en' className='flex items-center'>
                                <i className="fa-brands fa-tiktok text-2xl"></i>
                            </a>
                        </div>
                        <div className="col">
                            <a target="_blank" rel="noreferrer" href='https://twitter.com/EuodooTech' className='flex items-center'>
                                <i className="fa-brands fa-twitter text-2xl"></i>
                            </a>
                        </div>
                    </div>
                </div>
           </div>
         </div>
        
    )

}

export default Footer;