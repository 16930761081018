import Newsdb from "../data/newsdb";
import article from "../data/newsdb";
import { Link } from "react-router-dom";
import { useEffect } from "react";
const News = ()=>{

    useEffect(() => {
        const page = document.getElementById('news');
        page.scrollIntoView({behavior:'smooth',block:'start'});
    });

    return(
        <div id="news" className="container">
            <h1 className=" text-center text-uppercase featuredtitle">
                Our latest news and updates
            </h1>
            <div className="  px-3 row g-4 pt-5 pb-5 m-0">
               {/* d-flex justify-content-center  */}
                {
                    Newsdb.map((news, index)=>{
                        return(
             
                            <div key={index}  className=" col-12 col-lg-4 col-md-6" >
                                {news.filetype === "mp4" ?
                                <Link to={`/news/${index}`} style={{textDecoration:'none'}}>
                                    <div className=" card">
                                        <video src={news.img} className=" card-img-top" alt="..."> </video>
                                        <div className="card-body text-dark">
                                            <h5 className="card-title">{news.title}</h5>
                                            <p className="card-text">{news.description}</p>
                                            <button type="button" className="px-2 btn featuredtitlebutn">
                                                <b>View More</b>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                :
                                news.filetype === "png" ?
                                <Link to="/announceDetail" style={{textDecoration:'none'}}>
                                    <div className="card">
                                        <img src={news.img} className=" card-img-top" alt="..."/>
                                        <div className="card-body text-dark">
                                            <h5 className="card-title">{news.title}</h5>
                                            <p className="card-text">{news.description}</p>
                                            <button type="button" className="px-2 btn featuredtitlebutn">
                                                <b>View More</b>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                :
                                news.filetype === "jpg" ?
                                <Link to={`/fms-article/1`} style={{textDecoration:'none'}}>
                                    <div className="card">
                                        <img src={news.img} className=" card-img-top" alt="..."/>
                                        <div className="card-body text-dark">
                                            <h6 className="card-title">{news.title}</h6>
                                            <p className="card-text">{news.description}</p>
                                            <button type="button" className="px-2 btn featuredtitlebutn">
                                                <b>View More</b>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                :
                                news.filetype === "fms2" ?
                                <Link to={`/fms-article/2`} style={{textDecoration:'none'}}>
                                    <div className="card">
                                        <img src={news.img} className=" card-img-top" alt="..."/>
                                        <div className="card-body text-dark">
                                            <h6 className="card-title">{news.title}</h6>
                                            <p className="card-text">{news.description}</p>
                                            <button type="button" className="px-2 btn featuredtitlebutn">
                                                <b>View More</b>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                :
                                <Link to={`/news/${index}`} style={{textDecoration:'none'}}>
                                    <div className="card">
                                        <img src={news.img} className=" card-img-top" alt="..."/>
                                        <div className="card-body text-dark">
                                            <h5 className="card-title">{news.title}</h5>
                                            <p className="card-text">{news.description}</p>
                                            <button type="button" className="px-2 btn featuredtitlebutn">
                                                <b>View More</b>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                
                                }

                            </div>
                        )
                    
                    })
                }
               
            </div>
        </div>
    )
}

export default News;