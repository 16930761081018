import mainpic from "../../assets/announcement/PASANGMASDA/mainpic.jpg";
import p1 from "../../assets/announcement/PASANGMASDA/p1.jpg";
import p2 from "../../assets/announcement/PASANGMASDA/p2.jpg";
import p3 from "../../assets/announcement/PASANGMASDA/p3.jpg";
import p4 from "../../assets/announcement/PASANGMASDA/p4.jpg";
import p5 from "../../assets/announcement/PASANGMASDA/p5.jpg";
import p6 from "../../assets/announcement/PASANGMASDA/p6.jpg";
import p7 from "../../assets/announcement/PASANGMASDA/p7.jpg";
import p8 from "../../assets/announcement/PASANGMASDA/p8.jpg";
import { useEffect } from "react";
const Announcementdetail = ()=>{

    useEffect(() => {
        const page = document.getElementById('pasangmasda');
        page.scrollIntoView({behavior:'smooth',block:'start'});
    });

    return(
        <div id="pasangmasda" className="container announcementdetailpage">
            <h3 className="text-uppercase buildingtech">Euodoo Technologies Joins PASANGMASDA's Launching of New OFG Compliant Modern Jeepney</h3>
            <div className="row py-5 m-0">
                <div className="my-3 text-center col-12 col-md-6 col-lg-6">
                    <img src={mainpic} alt="pasangmasda" className="w-50"/>
                </div>
                <div className=" my-3 col-12 col-md-6 col-lg-6 textarticle">
                    Last April 28, 2023, the Euodoo Technologies team attended 
                    the launching of PASANGMASDA's new OFG compliant modern jeepney at 
                    the LTFRB-NCR Headquarters in Quezon City. The event was attended 
                    by Department of Transportation (DOTr) Secretary Jaime J. Bautista, 
                    LTFRB Chairperson Atty. Teofilo E. Guadiz III, Office of Transportation 
                    Cooperatives (OTC) Chairman Jesus Ferdinand D. Ortega, key executives of modern 
                    jeepney accessories, which includes Euodoo's CEO, Mr. Julius Evan P. Zapata. Also 
                    included are other transport groups in Metro Manila, which include 45 Transport Consolidated Entities of NCR.
                </div>
                <div className="my-3 col-12 col-md-6 col-lg-6 textarticle">
                    As a supplier of innovative and cutting-edge technology, Euodoo Technologies provided the 
                    CCTV, Automated Fare Collection System (AFCS), monitors, and GPS for the new modern jeepney. 
                    These features are essential for improving the safety and comfort of the passengers, as well 
                    as the drivers and operators. The AFCS is an efficient way of collecting fares, reducing the 
                    risk of corruption and improving the financial stability of the operators.
                </div>
                <div className="my-3 text-center col-12 col-md-6 col-lg-6">
                    <img src={p2} alt="pasangmasda" className="w-50"/>
                </div>

                <div className="my-3 text-center col-12 col-md-6 col-lg-6">
                    <img src={p3} alt="pasangmasda" className="w-50"/>
                </div>

                <div className="my-3 col-12 col-md-6 col-lg-6 textarticle">
                    The event showcased the latest technology that could make the jeepneys more comfortable and 
                    safer for passengers. The new modern jeepney is equipped with air conditioning units, GPS trackers, 
                    and CCTV cameras. These features could help monitor the driver's behavior, making them more responsible 
                    and accountable for their actions.
                </div>
                


                <div className="my-3 col-12 col-md-6 col-lg-6 textarticle">
                    The event also served as a reminder of why jeepney modernization should push through. 
                    It is a long-overdue initiative that would significantly improve the public transportation 
                    system in the country. The modernization program aims to replace the old and dilapidated jeepneys 
                    with new and safer vehicles that meet the standards set by the government. The program is also 
                    expected to reduce the carbon footprint of the public transportation sector, which could significantly 
                    contribute to the country's efforts to combat climate change.
                </div>
                <div className="my-3 text-center col-12 col-md-6 col-lg-6">
                    <img src={p4} alt="pasangmasda" className="w-50"/>
                </div>
                <div className="my-3 text-center col-12 col-md-6 col-lg-6">
                    <img src={p5} alt="pasangmasda" className="w-50"/>
                </div>
                <div className="my-3 col-12 col-md-6 col-lg-6 textarticle">
                    As one of the key players in the modernization program, Euodoo Technologies is committed to 
                    providing innovative and cutting-edge technology to improve the jeepney's safety and comfort. 
                    The company's CEO, Mr. Julius Evan P. Zapata, expressed his gratitude for being part of the launching 
                    event and his excitement for the future of the public transportation system in the country.
                </div>

                <div className="my-3 col-12 col-md-6 col-lg-6 textarticle">
                    In conclusion, the launching of PASANGMASDA's new OFG compliant modern jeepney is a significant 
                    step towards improving the public transportation system in the country. With the support of key 
                    players in the industry, such as Euodoo Technologies, the jeepney modernization program could become 
                    a reality, and the public transportation system in the country could significantly improve.
                </div>
                <div className="my-3 text-center col-12 col-md-6 col-lg-6">
                    <div className="row p-0 m-0">
                        <div className="col-12 mb-5 col-md-4 col-lg-4">
                            <img src={p6} alt="pasangmasda" className="w-100"/>
                        </div>
                        <div className="col-12 mb-5 col-md-4 col-lg-4">
                            <img src={p7} alt="pasangmasda" className="w-100"/>
                        </div>
                        <div className="col-12 mb-5 col-md-4 col-lg-4">
                            <img src={p8} alt="pasangmasda" className="w-100"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Announcementdetail;