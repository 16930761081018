import Act from "../data/activitydb";
import { useParams } from "react-router-dom";
import { useState,useEffect } from "react";

const NewsDetail = ()=>{
    const [news, setnews] = useState({});
     let { newsid } = useParams()

   useEffect(() => {
       
        const page = document.getElementById('newsdetail');
        page.scrollIntoView({behavior:'smooth',block:'start'});
        setnews(Act[newsid]);
        return () => {
            setnews({});
        }
      
    },[]);
 
    return(
        <div id="newsdetail" className=" container text-center newsdetail">
             <section className="text-center">
                <h1 className="text-uppercase text-center aboutusourcompanyprofile brand-quote mb-5">{news.title}</h1>
            </section>
            <section className="">
                {
                    news.gallery?.map((item,indx) => 
                        news.fileType === 'jpg' ?
                        <div key={indx} className="row pt-5 pb-5">
                            <div className="col-12 col-md-5 col-lg-5">
                                <img src={item.file} alt="activityimage" className=" image-fluid mb-5 pb-5"
                                style={{width:"90%"}}/>
                            </div>
                            <div className="col-12 col-md-7 px-5 col-lg-7">
                                <p>{item.description}</p>
                            </div>
                            
                        </div>
                        : 
                      
                        <div key={indx}>
                            <video src={item.file} className=" pb-3" controls style={{width:"50%"}}/>
                            <p>{item.description}</p>
                        </div>
                        
                        
                        
                    )
                }
            </section>
            
        </div>
    )
}

export default NewsDetail;