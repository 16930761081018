import team from "../data/teamdb.js";
import { useParams } from "react-router-dom";
import { React, useState,useEffect } from "react";
const Teampage =()=>{

    const [teamDetail, setTeamDetail] = useState({});
    let { teamId } = useParams()

   useEffect(() => {
        const page = document.getElementById('teampage');
        page.scrollIntoView({behavior:'smooth',block:'start'});
        setTeamDetail(team[teamId]);
        return () => {
            setTeamDetail({});
        }
    },[]);

    return(
        <div id="teampage" className=" teampage mb-5">
            <div className="bg">
                <h2 className=" text-uppercase text-center teamtext aboutusourcompanyprofile">meet the team</h2>         
            </div>
            <div className="main d-flex justify-content-center divcard container">
                <div className="card mb-5 px-2">
                    <div className="py-5 row g-0">
                        <div className="text-center col-md-5">
                            <img src={teamDetail.Image} style={{maxHeight:"130px", minWidth:"100px"}} className=" mb-3 img-fluid rounded-start" alt="..."/>
                            <h5  className=" aboutusourcompanyprofile  card-title">{teamDetail.name}</h5>
                            <b className=" "><p className="text-center">{teamDetail.role}</p> </b>
                            <div className="row">
                                <div className="col-2">
                                </div>
                                <div className="expertisecolumn col-8">
                                    <div className="row">
                                        <div className="contact-row"><img width="23" height="23" src="https://img.icons8.com/ios-filled/50/231557/phone.png" alt="phone"/>
                                        <small>{teamDetail.phone_number}<br/>{teamDetail.telephone}</small>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="contact-row"><img width="23" height="23" src="https://img.icons8.com/ios-glyphs/30/231557/new-post.png" alt="new-post"/><small>{teamDetail.email}</small></div>
                                    </div>
                                    <div className="row">
                                    <small  className=" expertise ">{teamDetail.expertise}</small>
                                    </div>
                                </div>
                                <div className="col-2">

                                </div>
                            </div>
                           
                        </div>
                        <div className=" col-md-7">
                            
                            <div style={{textAlign:"justify"}} className="px-5  card-body">

                                    {teamDetail.description} 
                              
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
export default Teampage;