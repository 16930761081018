import { services } from "../data/services";
import { useEffect } from "react";
import arrowup from "../assets/arrowup.png";

function Services (){

    useEffect(() => {

        const page = document.getElementById('service_page');
        page.scrollIntoView({behavior:'smooth',block:'start'});
        const backToTopButton = document.querySelector(".back-to-top");

        const scrollfunc = ()=>{
            if(backToTopButton)
                if(window.scrollY>100){
                    backToTopButton.style.display = "block";
                }else{  
                    backToTopButton.style.display = "none";
                }
        }
        window.addEventListener("scroll", scrollfunc);

    });

    return(

        <div id="service_page" className=" service_page container">

            <h2 id="whatweoffer" className=" text-uppercase text-center teamtext aboutusourcompanyprofile">What we offer</h2>
            <p className="text-center pb-5">
                euodoó Tech offers comprehensive 
                IT solutions and technical consulting services
                for various business function and industry-specific requirements
            </p>
           <div className="row g-4">
            {
                services.map((services,index)=>{
                    return(
   
                            <div key={index} className="text-center col-6 col-md-3 col-lg-3">
                               
                                <a id="servicesbutton" href={`#${services.id}`} style={{'textDecoration': 'none', color:"black"}}> 
                                    <div className="row">
                                        <div className="col">
                                            <img src={services.logo} style={{height:"80px", width:"100px"}}className="logoservices mx-auto pl-5 image-fluid" alt="..."/> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div id="servicetitle" className="col">
                                          {services.title}
                                        </div>
                                    </div>
                                     
                                </a> 
                              
                            </div>  
                   
                    )
                })
            }
            
        </div>
                                         

            <div className=" servicecontent pb-5 ">
            
                {
                    services.map((services,index)=>{
                        return(
                        
                            <div key={index} id={services.id} className="servicetext row g-3" > 
                                    <div id={services.id} className="row">
                                        <div className="col py-5">
                                        
                                        </div>
                                    </div>
                                    <h5 className="title">{services.title}</h5>
                                    <div className="col-12 col-lg-4 col-md-12 pb-5">
                                        <img src={services.img} style={{height:"250px", width:"270px"}}className=" mx-auto  pl-5 image-fluid" alt="..."/> 
                                    </div>
                                    <div className="col-12 col-md-1 col-lg-1"></div>
                                    <div className="col-12 col-lg-7 col-md-12">
                                        <p className="" style={{textAlign:"justify"}}>{services.content}</p>
                                        {/* <p className="" style={{textAlign:"justify"}}>{services.content[1]}</p> */}
                                        
                                        {/* {services.btn} */}
                                    </div>
                                
                            </div> 
                        )
                    })
                
                }
                
            </div> 
           
                <a className="back-to-top"  href="#whatweoffer" 
                style={{position: "fixed",bottom:"4%", right:"44%"}}>

                    <img id="back-to-top" src={arrowup} style={{height:"40px", width:"50px"}} className="" alt="arrowup"/> 

                
                </a>

            </div>
    )


}

export default Services;    
// icons8.com - iconsfrom