import {BrowserRouter, Routes,Route} from 'react-router-dom';
import Navigation from './components/navigation';
import Home from './pages/home';
import Footer from './components/footer';
import News from './pages/newsandupdates';
import Career from './pages/career';
import Notfound from './pages/notfound';
import Aboutus from './pages/aboutus';
import Teampage from './pages/teampage';
import Newsdetail from './pages/newsdetail';
import Service from './pages/servicespage';
import Coverage from './pages/coverage';
import Form from './pages/form';
import Eventmanagement from './pages/eventmanagement';

// --------------chat-----------------------------
import Chat from './components/chattest';

import { ADMINKEY, SCHEDULEKEY } from "./keyvariable";
import Webmail from './pages/webmail';
import Webmailpage from './pages/webmailpage';
import Schedule from './pages/admin pages/schedule';
import ScheduleClient from './pages/admin pages/schedule_client';
import Uploadannounce from './pages/admin pages/uploadannouncement'
// import Dashboard from './pages/admin pages/dashboard';
import Login from './pages/admin pages/login';

import 'smart-webcomponents-react/source/styles/smart.default.css';

// article
import Guidefms from './pages/article/guidetofms.js';
import Fms from './pages/article/fmsarticle';
import Phcvs from './pages/article/phcvsarticle';
import Announcementdetail from './pages/article/announcementdetail';
import Tester from "./pages/admin pages/testerdisplayimage";

function App() {
  return (
    <div className="container-fluid m-0 p-0 App ">
     
      <BrowserRouter>
        <Navigation/>
        <Routes>
          <Route path='/tester' element={<Tester/>}/>
          <Route path='/' element={<App/>}/>
          <Route index element= {<Home/>}/>
          <Route path='/news' element= {<News/>}/>
          <Route path='/careers' element= {<Career/>}/>
          <Route path='/aboutus' element= {<Aboutus/>}/>
          <Route path='/team/:teamId' element= {<Teampage/>}/>
          <Route path='/news/:newsid' element= {<Newsdetail/>}/>
          <Route path='/services' element= {<Service/>}/>
          <Route path='/package' element= {<Coverage/>}/>
          <Route path='/contactus' element= {<Form/>}/>
          <Route path='/eventmanagement' element= {<Eventmanagement/>}/>
          <Route path='/announceDetail' element= {<Announcementdetail/>}/>
          <Route path='/fms-article/:articleid' element= {<Fms/>}/> 
          <Route path='/guidetofms-article/:articleid' element= {<Guidefms/>}/> 
          <Route path='/phcvs-article/:articleid' element= {<Phcvs/>}/> 
          {/* <Route path='/webmail' element= {<Webmail/>}/>*/}
          <Route path={SCHEDULEKEY} element= {<Schedule/>}/> 
          <Route path='/schedule' element= {<ScheduleClient/>}/> 
          {/* <Route path={ADMINKEY} element= {<Dashboard/>}/> */}
          <Route path={ADMINKEY} element= {<Uploadannounce/>}/>
          {/* <Route path='/:webmailid' element= {<Webmailpage/>}/> */}
          {/* <Route path="/upload" element= {<Uploadannounce/>}/> */}
          {/* <Route path='/login' element= {<Login/>}/> */}
          <Route path='*' element={<Notfound/>}/>

        </Routes>
        <Footer/>    
      </BrowserRouter>
      <Chat/>
      {/* <Chat/> */}
    </div>
  );
}

export default App;

// import {useEffect} from "react"
// function App (){
  
//   useEffect(()=>{
//     localStorage.setItem("key", document.querySelector(".test").outerHTML);
    
//   },[])

//   // const buttonstate =()=>{
  
//   //  const firstresult =  document.getElementById("result").innerHTML = "hello"
//   //   localStorage.setItem("save1", firstresult)
//   // }
//   // const buttonstate1 =()=>{
  
//   //   const firstresult =  document.getElementById("result1").innerHTML = "hello again"
//   //   localStorage.setItem("save2", firstresult)
//   // }
//   return(
//     <div className="test">
//       {/* <button onClick={buttonstate}>Click me</button>
//       <p id="result"></p>
//       <button onClick={buttonstate1}>Click me again here</button>
//       <p id="result1"></p> */}
//         <button>heeloo</button>
//         <p>dsfsfsdfsf</p>
//     </div>
//   )
// }

// export default App;