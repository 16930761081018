import Act from "../data/activitydb";
import eventm from "../assets/eventmanagement/banner2.png";
import event from "../assets/eventmanagement/banner-pic2.png";
import event2 from "../assets/eventmanagement/section5.png";
import cvent from "../assets/eventmanagement/44.png";
import circle from "../assets/eventmanagement/circle.png";
import { useParams } from "react-router-dom";
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";


const Eventmanagement = ()=>{
 
    return(
        <div className="eventManagement m-0 p-0">

            <div className="row rowBanner m-0 p-0 " >
                <div className="bubbles">
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    
                </div>
                
                <div className="menu-right col-sm-12 col-md-6 col-lg-5 d-flex align-content-center flex-wrap" >
                    <img className="banner-pic" src={event2} alt="logo"/>
                </div>
                <div className="menu-left col-sm-12 col-md-6 col-lg-7 d-flex align-content-center flex-wrap p-3" style={{backgroundColor:"#A02EA2", color:"#ffffff"}} >
                    <h1 className="pb-2" style={{fontWeight:"bold"}}>Game-changing Event Platform</h1>
                    <h4 className="pb-2">From seamless event management to powerful audience engagement tools, we've got you covered!</h4>
                    <button type="button" className="btn btnEvent" style={{marginRight:"1%"}}>
                        Get in touch
                    </button>

                    <button className="btnEvent" >
                    Schedule an appointment
                    </button>
                    {/* <div className="box">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div> */}
                </div>
                
            </div>

            <center>
                <div className="section2 ">
                    <center>
                        <h2><span className="text-uppercase text-center aboutusourcompanyprofile" >
                        Create a memorable attendee experiences across diverse event formats
                        </span>
                        </h2> 
                        <p className="text-center fs-5 py-4 hometextp"><span className="fw-bold " >euodoó Technologies, Inc. </span>ensures that attendees have a seamless experience at your event. 
                        Whether your hybrid event is conducted in real-time, pre-recorded, or falls somewhere in between, we guarantees continuous engagement, 
                        facilitating event content discovery and consumption.</p>
                        
                    </center>
                    
                </div>
            </center>
            

            <div className="row section3 m-0 p-0 ">
                
                <div className="col-sm-12 col-md-6 align-self-center py-5" style={{color:"#000000"}}>
                    <h2 className="aboutuscardtitle text-center" >With our all-in-one event platform you can:
                    </h2>
                    <span className="fs-5 hometextp">
                        <ul className="eventUl">
                            <li><span className="bullet" >Say goodbye to scattered plans and endless spreadsheets</span></li>
                            <li><span className="bullet">Streamline your event planning, organization, and execution</span></li>
                            <li><span className="bullet">Whether virtual, hybrid, or onsite, we've got you covered.</span></li>
                        </ul>
                    </span>
                </div>
                <div className="colSection3 col-sm-12 col-md-6 align-self-center m-0 p-0 ">
                    <img className="cvent-pic" src={cvent} alt="logo"/>
                </div>
                {/* <div className="sectionCircle"></div> */}
            </div>

            <div className=" section4">
                <center>
                <h2 className="text-uppercase text-center aboutusourcompanyprofile" style={{width: "70%"}}>Ignite your event’s transformation with a single solution! </h2>
                <p className="fs-5 py-4 hometextp" style={{width: "70%"}}>An essential component of our service is delivering a comprehensive solution to our clients, ensuring that they have a memorable, 
                quantifiable, and valuable experience when hosting an event. At <span className="fw-bold " >Euodoó Technologies, Inc.</span>, we've got all your needs covered, offering dependable and secure solutions 
                for planning, promoting, and enhancing engagement for your virtual, hybrid, and in-person events.</p>
                <img className="circle-pic " src={circle} alt="logo"/>
                </center>
                
               
            </div>

            <div className="section5">
    
                <div className="section5Text">
                    <div style={{marginTop:"5%", marginBottom: "5%", width:"70%"}}>  
                        <h2 className="text-uppercase text-center aboutusourcompanyprofile" >Entrust your project with us</h2>
                        <p className="fs-5 hometextp text-center">Enhance the effectiveness of all your gatherings and functions by utilizing user-friendly, integrated event technology to maximize their impact.</p>
                    </div>
                </div>
                <div className="row section6 m-0 p-0">
                <div className="colSection3 col-sm-12 col-md-6 align-self-center m-0 p-0 ">
                    <img className="banner-pic2" src={eventm} alt="logo"/>
                </div>
                <div className="col-sm-12 col-md-6 align-self-center" style={{color:"#000000"}}>
                    <h4 className="hometextp" > You can contact us by either scheduling an appointment or reaching out to us.</h4>
                    <button type="button" className="btn btnEvent2" style={{marginRight:"1%"}}>
                    Get in touch
                    </button>
                    <button type="button" className="btn btnEvent2" style={{marginRight:"1%"}}>
                    Schedule an appointment
                    </button>
                </div>
                </div>
            </div>

            

        
            
            
        </div>
    )
}

export default Eventmanagement;