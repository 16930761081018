import { useEffect, useState, useRef } from 'react';

import axios from "axios";
import { SERVER, EMAILTEST, EMAILFROM } from "../../keyvariable";

import React from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week, WorkWeek, Month, Inject,formatDate, cellClick  } from '@syncfusion/ej2-react-schedule';
import { Internationalization, L10n, createElement } from '@syncfusion/ej2-base';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

L10n.load({
  'en-US': {
    'schedule': {
      'newEvent': 'Personal Information',
    },
  }
});

const Schedule = ()=>{
 
  const [agendas, setAgenda] = useState([]);
  const intl = new Internationalization();
  const occupiedEventTemplate = `<div class="occupied-cell"><span class="col-2 text-truncate occupied-text">Occupied</span></div>`;
  const [show, setShow] = useState(false);
  const [failedshow, setFailedshow] = useState(false);
  const PhilippinesTimeZone = "Asia/Manila";

  var Purpose
  var Prefix
  var Name
  var StartTime
  var EndTime
  var Companyname
  var Email
  var Phonenumber
  var Description
 

 const editForm = ()=>{

  Purpose = document.getElementById("purpose").value
  Prefix = document.getElementById("prefix").value
  Name =  document.getElementById("name").value
  Companyname =  document.getElementById("cname").value
  Email =  document.getElementById("email").value
  Phonenumber =  document.getElementById("phoneNumber").value
  Description =  document.getElementById("description").value

 }

  const [contact, setContact] =  useState({
      from: `NOTIFICATION FOR SCHEDULE APPOINTMENT ${EMAILFROM}`,
      to: "", 
      subject: "Notification for Appointment",
      html:""
  })

  useEffect(()=>{
    const page = document.getElementById('clientsched');
    page.scrollIntoView({behavior:'smooth',block:'start'});
    axios.get(SERVER+"schedule").then(res=>{
      setAgenda(res.data);
    }).catch(err => console.log(err))
  },[])

  const handleActionComplete = (args)=>{
    if (args.requestType === 'eventCreate') {
      const details={
        Subject: args.data[0].Subject,
        Prefix: document.getElementById("prefix").value,
        Name: document.getElementById("name").value,
        StartTime: args.data[0].StartTime,
        EndTime: args.data[0].EndTime,
        Companyname: document.getElementById("cname").value,
        Email: document.getElementById("email").value,
        Phonenumber: document.getElementById("phoneNumber").value,
        Description: document.getElementById("description").value,
        Status: 'Pending',
        isSlotAvailable: false
      }
      
      

        axios.post(SERVER+"schedule", details).then(()=>{
          setShow(true);
          // setAgenda([...agendas, details])
          const contactForm = {
            // euodoocustomercare@gmail.com
              from : contact.from,
              to : 'euodoocustomercare@gmail.com',
              subject: contact.subject,
              html: `
              
                  <div class="card container">
                    <div class="card-body">
                      <p class="card-title">Good day! </p>
                      <p class="card-text">This email notifies you that ${args.data[0].Name} with email of ${args.data[0].Email} requests an appointment for the purpose of ${args.data[0].Subject}.
                      To see the status of his/her appointment request, please check this link:  www.euodoo.com.ph/fgdhyhrthrdhthello1.</p>
                      <p class="card-text">Thank you.</p>
                    </div>
                    <div class="card-footer">
                        <br/>
                        © 2023 | Euodoo Technologies Inc., 10/F One Global Place 25th Street cor. 5th Avenue Bonifacio Global City, 
                        Taguig 1632 Philippines | www.euodoo.com.ph
                    </div>
                  </div>
              
              `
            }
          axios.post(SERVER + "send", contactForm)
        }).catch((error)=>{
          setFailedshow(true)
          console.log(error)
      })
      
      
    }
}

  const timeScale = { enable: true, interval: 60, slotCount: 1};

  //for more details
  function editorTemplate(props) {
    return (props !== undefined ?<table className="custom-event-editor" ><tbody>
    <tr><td className="e-textlabel">Purpose </td><td colSpan={4}>
        <input defaultValue={props.Subject} onChange={editForm} id="purpose" className="e-field e-input" type="text" name="Subject" />
      </td></tr>
      <tr><td className="e-textlabel">Prefix</td><td colSpan={4}>
        <DropDownListComponent value={props.Prefix} id="prefix" name="Prefix" className="e-field" dataSource={['Mr.', 'Ms.']}></DropDownListComponent>
      </td></tr>
      <tr><td className="e-textlabel">Full Name</td><td colSpan={4}>
        <input defaultValue={props.Name} onChange={editForm} id="name" className="e-field e-input" name="Name" colSpan={4}></input>
      </td></tr>
      <tr><td className="e-textlabel">From</td><td colSpan={4}>
        <DateTimePickerComponent step={60} format='dd/MM/yy hh:mm a' id="StartTime" name="StartTime" value={new Date(props.startTime || props.StartTime)} className="e-field"></DateTimePickerComponent>
      </td></tr>
      <tr><td className="e-textlabel">To</td><td colSpan={4}>
        <DateTimePickerComponent step={60} format='dd/MM/yy hh:mm a' id="EndTime" name="EndTime" value={new Date(props.endTime || props.EndTime)} className="e-field"></DateTimePickerComponent>
      </td></tr>
      <tr><td className="e-textlabel">Company Name</td><td colSpan={4}>
      <input defaultValue={props.Companyname} onChange={editForm} id="cname" className="e-field e-input" name="Companyname" colSpan={4}></input>
      </td></tr>
      <tr><td className="e-textlabel">Email</td><td colSpan={4}>
      <input defaultValue={props.Email} onChange={editForm} id="email" className="e-field e-input" type="email" name="Email"/>
      </td></tr>
      <tr><td className="e-textlabel">Phone Number</td><td colSpan={4}>
      <input defaultValue={props.Phonenumber} onChange={editForm} id="phoneNumber" className="e-field e-input" type="number" name="Phonenumber"  />
      </td></tr>
      <tr><td className="e-textlabel">Description</td><td colSpan={4}>
      <textarea defaultValue={props.Description} onChange={editForm} id="description" className="e-field e-input" type="text" name="Description"  />
      </td></tr>
      </tbody></table>
       : <div></div>);
  }

  const contentTemplate = (props) => {
    return (<div className="quick-info-content">
      {props.elementType === 'cell' ?
       <div>
        
       </div>
        :
        <div className="event-content">
          
        </div>
        }
    </div>);
  }

  const headerTemplate = (props) => {
    return (<div className="quick-info-header">
      <div className="quick-info-header-content" style={getHeaderStyles(props)}>
        <div className="quick-info-title">{getHeaderTitle(props)}</div>
        <div className="duration-text">{getHeaderDetails(props)}</div>
      </div>
    </div>);
  }
  const getHeaderStyles = (data) => {
    if (data.elementType === 'cell') {
      return { textAlign: 'center', background: '#e3165b',color: 'white', fontSize: '12px', margin: '20px 20px 0 20px', padding: '15px'};
    }
    else {
      return { background: '#e3165b', color: '#FFFFFF', fontSize: '12px', margin: '20px 20px 0 20px', padding: '15px' };

    }
  }
  const getHeaderTitle = (data) => {
    return (data.elementType === 'cell') ? 'Add Appointment' : 'Appointment Details';
  }
  const getHeaderDetails = (data) => {
    return intl.formatDate(data.StartTime, { type: 'date', skeleton: 'full' }) + ' (' +
      intl.formatDate(data.StartTime, { skeleton: 'hm' }) + ' - ' +
      intl.formatDate(data.EndTime, { skeleton: 'hm' }) + ')';
  }

  const quickInfoTemplates ={
    header: headerTemplate,
    content: contentTemplate,
  }
  //disable double click showing editor
  function onCellDoubleClick (args) {
    args.cancel = true;
  }

  function handleEventRendered (args) {
    if (args.data.isSlotAvailable === false || args.element.classList.contains('e-appointment')) {
      args.element.style.backgroundColor = 'white'; 
    }

    
  }

  function onPopupOpen(args) { 

    let headerCell = args.target.classList.contains('e-header-cells') // prevents clicking in header cell
    if ((args.type === 'QuickInfo' || args.type === 'Editor') && headerCell) { 
      args.cancel = true; 
    }

    if (args.type === 'QuickInfo' || args.type === 'Editor') //to disable past time
    {
      if(isValidAction(args.data.StartTime)){
        alert("Invalid selection! Please choose present time and dates only.");
        args.cancel = true; 
      }
      else if (args.data.isSlotAvailable === false){
        alert("Time is already occupied. Please choose another time.");
        args.cancel = true; 
      }
      else if (isBreak(args.data.StartTime, args.data.EndTime)) {

        alert("Please choose an appropriate time.");
        args.cancel = true;
      }
    }

    if(args.type === 'QuickInfo') { 
      if(args.element.querySelector('.e-cell-popup')){
        args.element.querySelector('.e-event-create').style.display = 'none'; 
      }
      else if (args.element.querySelector('.e-event-popup')){
        args.element.querySelector('.e-event-delete').style.display = 'none'; 
      }
    } 
     
    if (args.type === "Editor") {
      const formElement = args.element.querySelector(".e-schedule-form")
      formElement.ej2_instances[0].addRules('Subject', { required: true });
      formElement.ej2_instances[0].addRules('Prefix', { required: true });
      formElement.ej2_instances[0].addRules('Name', { required: true }); 
      formElement.ej2_instances[0].addRules('StartTime', { required: true }); 
      formElement.ej2_instances[0].addRules('EndTime', { required: true }); 
      formElement.ej2_instances[0].addRules('Companyname', { required: true });
      formElement.ej2_instances[0].addRules('Email', { required: true, email: true });
      formElement.ej2_instances[0].addRules('Phonenumber', { required: true, digits: true, maxLength: 11, minLength: 10 }); 
    }

  } 

  const isValidAction = (date) => {
    return !(date.getTime() > new Date().getTime());
    
  }
  const isBreak = (startDate, endDate) => {
    const lunchBreakStartTime = new Date(startDate);
    lunchBreakStartTime.setHours(12, 0, 0);

    const lunchBreakEndTime = new Date(endDate);
    lunchBreakEndTime.setHours(13, 0, 0);
    return (startDate.getTime() >= lunchBreakStartTime && endDate.getTime() <= lunchBreakEndTime);
  }

  const eventSettings = { dataSource: agendas, enableMaxHeight: true, template: occupiedEventTemplate,};

  const onCellTemplate = (props) => {
    
    const { date } = props;

    const lunchBreakStartTime = new Date(date);
    lunchBreakStartTime.setHours(12, 0, 0);

    const lunchBreakEndTime = new Date(date);
    lunchBreakEndTime.setHours(13, 0, 0);

    const isLunchBreak = date >= lunchBreakStartTime && date < lunchBreakEndTime;
    

    if (isLunchBreak) {
      return (
        <div className='lunch-break' style={{ pointerEvents: 'none', width: '100%'}}>
        <span style={{ fontWeight: '500'}}>Break</span>
        </div>
      );
      
    }
  }

  return(
    <div className="container " id="clientsched" style={{paddingTop: "100px", minHeight: "80vh", paddingBottom: "100px"}}>
    <p style={{fontStyle: 'italic', color:'red'}}>** Please note that the following date and time is set in Philippine timezone. ** </p>

    <Alert show={show} className=' fixed-top' variant="info">
                    <Alert.Heading>Request appointment successfully submitted!</Alert.Heading>
                    <p>
                        Thank you for reaching us, your request for an appointment is currently on pending. 
                        We'll reach out to you via email regarding the status of your request.
                    </p>
                    <div className="d-flex justify-content-end">
                    <Button onClick={() => setShow(false)} className='"btn-close" data-bs-dismiss="alert" aria-label="Close"' variant="outline-info text-dark">
                        <b>Dismiss</b>
                    </Button>
                    </div>
                </Alert>
                {/* -----------------------------------------end success alert-------------------------------------------------------- */}

                {/* -----------------------------------------failed alert-------------------------------------------------------- */}
    <Alert show={failedshow} className=' fixed-top' variant="danger">
                    <Alert.Heading>Oops! Message unsuccessful</Alert.Heading>
                    <p>
                        Please try again or check your network. You may message us directly through our email at customercare@euodoo.com.ph.
                    </p>
                    <div className="d-flex justify-content-end">
                    <Button onClick={() => setFailedshow(false)} className='"btn-close" data-bs-dismiss="alert" aria-label="Close"' variant="outline-danger ">
                        <b>Dismiss</b>
                    </Button>
                    </div>
     </Alert>

     <ScheduleComponent
      width='100%'
      height='493px'
      selectedDate={new Date()}
      eventSettings ={eventSettings} 
      eventRendered={handleEventRendered}
      actionBegin={handleActionComplete}
      startHour='10:00' 
      endHour='17:00'
      timeScale={timeScale}
      editorTemplate={editorTemplate}
      cellDoubleClick={onCellDoubleClick}
      popupOpen={onPopupOpen}
      allowDragAndDrop={false}
      quickInfoTemplates={quickInfoTemplates}
      cellTemplate={onCellTemplate}
      timezone={PhilippinesTimeZone}
      
    >
      <ViewsDirective>
      <ViewDirective option='WorkWeek' />
      </ViewsDirective>
      <Inject services={[ WorkWeek]} />
    </ScheduleComponent>
    </div>
  )
 }
  
 export default Schedule;