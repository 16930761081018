import jeepney from "../assets/news/launching/pm_jeepney_launching1.png";
import fb from "../assets/news/videos/NewsLive.mp4";
import euodoonews from "../assets/news/videos/NewsVid.mp4";
import metrocomet from "../assets/news/newsthumbnail/metrocomet.png";
import lucban from "../assets/news/newsthumbnail/lucbangenesis.png";
import boracay from "../assets/news/newsthumbnail/boracayland.png";
import alabang from "../assets/news/newsthumbnail/alabangtransport.png";
import sampol from "../assets/news/newsthumbnail/sampolbulac.png";
import modernnorth from "../assets/news/newsthumbnail/modernnorth.png";
import modernmin from "../assets/news/newsthumbnail/modernmindanao.png";
import mainpic from "../assets/announcement/PASANGMASDA/mainpics.jpg";
import fms from "../assets/news/article/fms1.jpg";
import fms2 from "../assets/news/article/fms2-t.png";
// import puvmp from "../assets/news/newsthumbnail/puvmpact.png";

const Newsdb = [
    {
        id:2,
        img:fms2,
        title: "Stay Ahead of the Competition: A Foolproof Guide to Selecting the Right Fleet Management System",
        description: " Fleet management plays a crucial role in...",
        filetype: "fms2"
    },

    {
        id:1,
        img:fms,
        title: "10 Game-Changing ways a fleet management system can skyrocket your business success!",
        description: " In today's fast-paced business world, efficent...",
        filetype: "jpg"
    },
    {
        id:"pasangmasda",
        img:mainpic,
        title: "Euodoo joins pasangmasda Launching of new OFG compliant modern jeepney",
        description: " Last April 28, 2023, the Euodoo Te... ",
        filetype: "png"
    },
    {
        id:0,
        img:jeepney,
        title:"PM Jeepney launching",
        description:"In October 29, 2018 at 10am in the morning, marked an important milestone in..."

    },
    {
        id:1,
        img:fb,
        title:"Facebook live by Jacque Manabat",
        description:"A short clip of facebook live by Jacque Manabat",
        filetype:"mp4"
    },
    {
        id:2,
        img:euodoonews,
        title:"euodoó featured in News Articles",
        description:"Completing the modernized look and features of the... ",
        filetype:"mp4"
    },
    {
        id:3,
        img:metrocomet,
        title:"Metro Comet TSC Launching Activities",
        description:"Office of Transport Cooperatives member Metro Comet..."
        
    },
    {
        id:4,
        img:lucban,
        title:"Lucban Genesis TSMPC Launching Activities",
        description:"Lucban, the 'Art Capital', home of the prestigious and colorful ..."
        
    },
    {
        id:5,
        img:boracay,
        title:"Boracay Land Transport MPC Launching Activities",
        description:"Tourists as well as residents of the island resort of Boracay..."

    },
    {
        id:6,
        img:alabang,
        title:"Alabang Transport Launching Activities",
        description:"Isuzu Philippines Corporation (IPC), continues to support PUV mode..."

    },
    {
        id:7,
        img:sampol,
        title:"Sampol Bulac TSC Launching Activities",
        description:"In a formal ceremony held last July 26, Isuzu Philippines Corporation..."

    },
    {
        id:8,
        img:modernnorth,
        title:"Modern PUV Caravan North Luzon Phase",
        description:"Isuzu Philippines Corporation (IPC) showed its unwavering support..."

    },
    {
        id:9,
        img:modernmin,
        title:"Modern PUV Caravan Mindanao Phase",
        description:"Showcasing the PUV Modernization Program down to the grassroots..."

    } ,

    // ,{
    //     id:10,
    //     img:puvmp,
    //     description:"Showcasing the PUV Modernization Program down to the grassroots level, the Depa..."
        
    // }
];

export default Newsdb;
