import React, { useState,useRef,useEffect } from 'react';
// import emailjs from '@emailjs/browser';
// import {SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY} from '../keyvariable'
// import featuredeuodoo from "../assets/video/euodooFeaturedVideo.mp4"
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { SERVER, EMAILFROM, EMAILTO } from "../keyvariable";
import axios from "axios";

 const Form = () => {

    useEffect(() => {
       
        const page = document.getElementById('contactform');
        page.scrollIntoView({behavior:'smooth',block:'start'});
      
    },[]);
   
  const [show, setShow] = useState(false);
  const [failedshow, setFailedshow] = useState(false);

//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
//       .then((result) => {
//         document.getElementById("contactform").reset();
//         setShow(true);
//         console.log(result.text);

//       }, (error) => {
//         setFailedshow(true)
//         console.log(error.text);
//       });
//   };


const [contact, setContact] =  useState({
    from: `EUODOO CUSTOMER INQUIRY ${EMAILFROM}`,
    to: "EMAILTO", 
    subject: "Euodoo Technologies Inc. Inquiry",
    html:""
})

const [form, setForm] = useState({
    name: "",
    company: "",
    email: "",
    mobile: "",
    country:"",
    message: ""
})

const onchangeForm = e=>{
    const locatevalue = e.target.getAttribute("name");
    const fieldvalue = e.target.value;
    const createform = {...form};
    createform[locatevalue] = fieldvalue;
    setForm(createform);
}

const SubmitInquiry = e=>{
    e.preventDefault();
    const contactForm = {
        from : contact.from,
        to : contact.to,
        subject: contact.subject,
        html: `
        
            <div class="card container">
                <div class="card-body">
                    <h5 class="card-title">New message from ${form.name}</h5>
                    <p class="card-text"><b>Fullname:</b> ${form.name}</p>
                    <p class="card-text"><b>Company:</b> ${form.company}</p>
                    <p class="card-text"><b>Email Address:</b> ${form.email}</p>
                    <p class="card-text"><b>Country:</b> ${form.country}</p>
                    <p class="card-text"><b>Mobile Number: </b>${form.mobile}</p>
                    <p class="card-text"><b>Message: </b>${form.message}</p>
                </div>
                <div class="card-footer">
                    © 2023 | Euodoo Technologies Inc., 10/F One Global Place 25th Street cor. 5th Avenue Bonifacio Global City, 
                    Taguig 1632 Philippines | www.euodoo.com.ph
                </div>
            </div>
        
        `
    }

    axios.post(SERVER + "send", contactForm).then((res)=>{
        document.getElementById("form").reset();
        setShow(true);
        }).catch((error)=>{
        setFailedshow(true)
        console.log(error)
    })
}



  return (
    <div className='ContactForm ' id="contactform" style={{minHeight: "100vh", marginTop:"60px"}}>
        <div className='pt-5 my-3 header text-center'>
            
            <div className='contactheader'>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <h4 className='aboutusourcompanyprofile '>DO YOU WANT TO BOOK AN APPOINTMENT WITH US? REFER TO THE LINK BELOW:</h4>
                <p> <a href="/schedule" target='_blank' style={{color: '#231557', fontStyle: '18px'}}><b>Click here.</b></a> </p>
            </div>
            
        </div> 

        <div className="contact-container">

            <div className="contact-info">
                <div className="box">
                    <div className="icon"><img width="23" height="23" src="https://img.icons8.com/ios-filled/50/231557/marker.png" alt="marker"/></div>
                    <div className="text">
                        <h3>Address</h3>
                        <p>10/F One Global Place 25th Street cor. <br/>
                        5th Avenue Bonifacio Global City,<br/>
                        Taguig 1632 Philippines</p>
                    </div>
                </div>
                <div className="box">
                    <div className="icon"><img width="23" height="23" src="https://img.icons8.com/ios-filled/50/231557/phone.png" alt="phone"/></div>
                    <div className="text">
                        <h3>Telephone</h3>
                        <p>+632-3224-4177 <br/>
                        +63-960-471-9671</p>
                    </div>
                </div>
                <div className="box">
                    <div className="icon"><img width="23" height="23" src="https://img.icons8.com/ios-glyphs/30/231557/new-post.png" alt="new-post"/></div>
                    <div className="text">
                        <h3>Email</h3>
                        <p>customercare@euodoo.com.ph</p>
                    </div>
                </div>
            </div>

            <div className='form'>

            <Alert show={show} className=' fixed-top' variant="info">
                    <Alert.Heading>Message successfully submitted!</Alert.Heading>
                    <p>
                        Thank you for reaching us, we'll give you a feedback soon.
                    </p>
                    <div className="d-flex justify-content-end">
                    <Button onClick={() => setShow(false)} className='"btn-close" data-bs-dismiss="alert" aria-label="Close"' variant="outline-info text-dark">
                        <b>Dismiss</b>
                    </Button>
                    </div>
                </Alert>
                {/* -----------------------------------------end success alert-------------------------------------------------------- */}

                {/* -----------------------------------------failed alert-------------------------------------------------------- */}
                <Alert show={failedshow} className=' fixed-top' variant="danger">
                    <Alert.Heading>Oops! Message unsuccessful</Alert.Heading>
                    <p>
                        Please try again or check your network. You may message us directly through a given email below.
                    </p>
                    <div className="d-flex justify-content-end">
                    <Button onClick={() => setFailedshow(false)} className='"btn-close" data-bs-dismiss="alert" aria-label="Close"' variant="outline-danger ">
                        <b>Dismiss</b>
                    </Button>
                    </div>
                </Alert>
                {/* -----------------------------------------end failed alert-------------------------------------------------------- */}
                {/* <form className='p-5' ref={form} onSubmit={sendEmail} id="contactform">
                    <div className="mb-4">
                        
                        <input type="text" name = "name" className="form-control" placeholder="Full Name" id="name" aria-describedby="emailHelp" required/>
                    </div>
                    <div className="mb-4">
                        <input type="text" name = "company" className="form-control" placeholder="Company Name" id="name" aria-describedby="emailHelp" required/>
                    </div>
                    <div className="mb-4">
                        <input type="email" name = "email" className="form-control" placeholder="Email address" id="exampleInputEmail1" aria-describedby="emailHelp" required/>
                    </div>
                    <div className="mb-4">
                        <input type="number" name = "mobile" className="form-control" placeholder="Mobile Number" id="name" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-4">
                        <input type="text" name = "country" className="form-control" placeholder="Country" id="name" aria-describedby="emailHelp" required/>
                    </div>
                    <div className="form-floating">
                        <textarea className="form-control" name = "message" placeholder="Leave a message here" id="floatingTextarea" required></textarea>
                        <label className='text-dark'>Message</label>
                    </div>
                    <button type="submit" className="formbutton btn mt-4" value="Send" >Submit</button>
                </form> */}

                <form onSubmit={SubmitInquiry} className='p-5' id="form">
                    <div className="mb-4">
                        <input onChange={onchangeForm} type="text" name = "name" className="form-control" placeholder="Full Name" id="name" aria-describedby="emailHelp" required/>
                    </div>
                    <div className="mb-4">
                        <input onChange={onchangeForm} type="text" name = "company" className="form-control" placeholder="Company Name" id="name" aria-describedby="emailHelp" required/>
                    </div>
                    <div className="mb-4">
                        <input onChange={onchangeForm} type="email" name = "email" className="form-control" placeholder="Email address" id="exampleInputEmail1" aria-describedby="emailHelp" required/>
                    </div>
                    <div className="mb-4">
                        <input onChange={onchangeForm} type="number" name = "mobile" className="form-control" placeholder="Mobile Number" id="name" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-4">
                        <input onChange={onchangeForm} type="text" name = "country" className="form-control" placeholder="Country" id="name" aria-describedby="emailHelp" required/>
                    </div>
                    <div className="form-floating">
                        <textarea onChange={onchangeForm} className="form-control" name = "message" placeholder="Leave a message here" id="floatingTextarea" required></textarea>
                        <label className='text-dark'>Message</label>
                    </div>
                    <button type="submit" className="formbutton btn mt-4" value="Send" >Submit</button>
                </form>
            </div>

        </div>
        
                

    </div>
  );
};

export default Form;

