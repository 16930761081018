import { useState,useEffect } from "react";
import axios from 'axios';
import { SERVER } from "../../keyvariable";
import Adminpage from '../../components/adminmenu';

function Announcement  (){

    const [file, setfile] = useState();
    const [announcements, setannouncements] = useState([]);

    useEffect(()=>{
        axios.get(SERVER).then(res=>{
            setannouncements(res.data)
        }).catch(err => console.log(err))
    })

    const handlefile = (e)=>{
        setfile(e.target.files[0])
    }
    const handleupload=()=>{
        const formdata = new FormData();
        formdata.append('announcement', file);
        axios.post(SERVER,formdata).then(()=> {
             document.getElementById("uploadinput").value = "";
            }
            
        )
        .catch(err => console.log(err))
    }
    const deleteannouncment=(e,announce)=>{
        e.preventDefault();
        axios.delete(SERVER + announce).then(()=>{
            setannouncements(announcements.filter(announce => announce._id !== announce))
        })
    }
    
    const inactive=(e,announcement)=>{
        e.preventDefault();
        const status = {status:"inactive"}
        axios.put(SERVER + announcement, status).then((res)=>{
            const index = announcements.findIndex(announce=>announce._id === announcement);
            const announce = [...announcements];
            announce[index]=status;
            setannouncements(announce);
            alert("announcement deactivated")
        }).catch(err=>console.log(err))
    }

    const active=(e,announcement)=>{
        e.preventDefault();
        const status = {status:"active"}
        axios.put(SERVER + announcement, status).then((res)=>{
            const index = announcements.findIndex(announce=>announce._id === announcement);
            const announce = [...announcements];
            announce[index]=status;
            setannouncements(announce);
            alert("announcement activated")
        }).catch(err=>console.log(err))
    }

    return(
        <div className="announcement ">

            <div className="row m-0 p-0"> 
                <div className="col-2 menutabannouncement">
                    <Adminpage/>
                </div>
                <div className="col">
                    <input id="uploadinput" type="file" className="btn btn-secondary" onChange={handlefile}/>
                    <button type="button" className=" my-3 btn btn-info" onClick={handleupload}>upload</button>

                    <table className="table">
                        <thead>
                            <tr>
                                <th>Announcements</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            announcements.map((data, id)=>{
                                return(  
                                        <tr  key={id}>
                                            <td><img src={`${SERVER}/uploads/${data.name}`} className="w-25"/></td>
                                            <td>
                                                <div className="row">
                                                    <div className="m-1 col col-lg-4"><button type="button" className="btn btn-success" onClick={e=>active(e, data._id)}>Activate</button></div>
                                                    <div className="m-1 col col-lg-4"><button type="button" className="btn btn-secondary" onClick={e=>inactive(e, data._id)}>Deactivate</button></div>
                                                    <div className="m-1 col col-lg-4"><button type="button" className="btn btn-outline-danger" onClick={e=>deleteannouncment(e,data._id) }>
                                                    Delete</button></div>
                                                </div>
                                            
                                            </td>

                                        </tr>
                                                
                            )
                                        
                            }).sort().reverse()
                        }
                        </tbody>
                    </table>

                </div>
            </div>
          
        </div>
    )
} 

export default Announcement;

// source : https://www.youtube.com/watch?v=YH63fnqG7F0
// using mysql: https://www.youtube.com/watch?v=dLXSJdTK9QI