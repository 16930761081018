import ceo from "../assets/team/whitebg/jepz.png";
import headadmin from "../assets/team/whitebg/ap.png";
import vpforsales from "../assets/team/whitebg/christine.png";
import headfinance from "../assets/team/whitebg/cmmorales.png";
import accountmanager from "../assets/team/whitebg/aj.png";
import projectmanager from "../assets/team/whitebg/gi.png";
import unknown from "../assets/team/whitebg/dummy.png";
import lett from "../assets/team/whitebg/let.png";
import sam from "../assets/team/whitebg/sam.png";
import miguel from "../assets/team/whitebg/miguel.png";
import stephen from "../assets/team/whitebg/stephen.png";
import vivian from "../assets/team/whitebg/vivian.png";
import nerisse from "../assets/team/whitebg/nerisse.png"

const team = [
    {
        id:0,
        Image: ceo,
        name: "Julius Evan P. Zapata",
        role: "President & CEO",
        phone_number: "+632-7088-7101",
        email: "jepzapata@euodoo.com.ph",
        description:
           <>
                <>
                    Julius is an experienced Information and Communications Technology (ICT) 
                    Specialist with over 20 years of experience in delivering exceptional IT 
                    solutions across multiple industries. His expertise lies in the Oil and Gas 
                    industry, Housing industry, National Government Agencies, banking, and private sector.
                </> 
                <>
                <br/>   <br/> 
                    Throughout his career, Julius has made significant contributions to various organizations, 
                    and he has been a Consultant for several prestigious entities, including the US Agency for 
                    International Development (USAID), Philippine Amusement and Gaming Corporation (PAGCOR), Bureau 
                    of Internal Revenue (BIR), Philippine National Oil Company Exploration Corporation, National Housing 
                    Authority (NHA), Bank of Florida (a Rural Bank), Rang-ay Bank, and The Straits Network.
                </> 
                <>
                <br/>  <br/> 
                    Julius holds an Undergraduate degree in Computer Engineering and a Master's Degree 
                    in Public Administration, specializing in Spatial Information Management. His academic 
                    achievements were attained from the University of the Philippines and ITC Netherlands.
                </> 
                <>
                <br/>  <br/> 
                    Throughout his career, Julius has demonstrated exceptional technical skills, leadership, and 
                    professionalism, earning him the reputation as a reliable and trusted ICT Specialist. His passion 
                    for technology and commitment to delivering quality results makes him an asset to any organization.
                </> 
            </>
            ,
        expertise:
        
        <div style={{display:"flex", flexDirection:'column'}}>
                <h6 className="py-3 card-title">Areas of Expertise</h6>
                <ul className="px-3 bullets">
                <li>Processes, Design and Implementation of Systems, Network, and Communication Infrastructures</li>
                <li>Solutions for both government and private sectors in the areas of ICT Strategies, Communications, 
                    and Software implementations </li>
                <li>Engineering Support covering technologies such as 3D modeling and Printing, Cognitive Computing, 
                    IT/OT Convergence, Mobility and Upstream Modeling Suites.</li>
                </ul>
        </div>
            // <ul className="">
            //     <h6 className=" card-title">Areas of Expertise</h6>
            //     <li>Processes, Design and Implementation of Systems, Network, and Communication Infrastructures</li>
            //     <li>Solutions for both government and private sectors in the areas of ICT Strategies, Communications, 
            //         and Software implementations </li>
            //     <li>Engineering Support covering technologies such as 3D modeling and Printing, Cognitive Computing, 
            //         IT/OT Convergence, Mobility and Upstream Modeling Suites.</li>
            // </ul>
       

    },
    {
        id:1,
        Image: headadmin,
        name: "Allen P, Zapata",
        role: "Head, Admin & Operations",
        phone_number: "+632-7088-7101",
        email: "apzapata@euodoo.com.ph",
        description:
            <>
                <>
                    Allen is a natural-born entrepreneur who is driven by his passion for business planning, 
                    product development, and personnel training. With over 15 years of experience in various 
                    industries, he has proven himself to be a valuable asset in terms of administrative and 
                    operational tasks, marketing, relationship-building with partners, manufacturers, and government agencies.
                </> <br/> <br/>
                <>
                    Allen has a strong background in sales and corporate marketing activities, having organized 
                    and led product roadshows and campaigns. He possesses excellent interpersonal and communication 
                    skills, making him a skilled presenter during corporate presentations.
                </> <br/> <br/>
                <>
                    Allen graduated with a Bachelor of Science in Commerce major in Management from Far Eastern University. 
                    He has a keen eye for identifying business opportunities and formulating strategies to maximize them. 
                    With his strong leadership skills and attention to detail, he has successfully led teams in delivering 
                    exceptional results in various projects.
                </> 
            </>,

        expertise:
        <div style={{display:"flex", flexDirection:'column'}}>
                <h6 className="py-3 card-title">Areas of Expertise</h6>
                <ul className="px-3 bullets">
                <li>People Management</li>
                <li>Public Relations</li>
                <li>Admin and Operations Management</li>
                <li>Budgeting and Financial Management</li>
                <li>Performance Tracking</li>
                <li>Establishing Policies that Promote Company Culture</li>
                <li>Process Improvement</li>
                </ul>
        </div>
    },
    {
        id:2,
        Image: vpforsales,
        name: "Christine Q, Mamaril",
        role: "VP for Sales & Marketing",
        phone_number: "+632-7088-7101",
        email: "cqmamaril@euodoo.com.ph",
        description:
           
                <>
                    A sales and marketing vice president is responsible for managing brand awareness, 
                    developing strategic promotional plans, and preparing marketing campaigns to attract existing. 
                    Potential customers increase sales revenues and improve the company's profitability. 
                    A sales and marketing vice president monitors the sales team, reviews the sales budget, 
                    researches the current industry trends to identify business opportunities, and allocates 
                    resources efficiently. A sales and marketing vice president must have excellent leadership 
                    and communication skills to discuss services with clients and set long-term sales development 
                    goals.

                </> 

          
       
    },
    {
        id:3,
        Image: headfinance,
        name: "Christopher M. Morales",
        role: "Head, Finance & Accounting",
        phone_number: "+632-7088-7101",
        email: "cmmorales@euodoo.com.ph",
        description:
         
                <>
                    Heads of finance oversee the financial activities of an organization. Heads of 
                    finance forecast financial results, manage budgets and conduct risk management.

                </> 

           
    },
    {
        id:4,
        Image: accountmanager,
        name: "Alain-Jeth R. Castro",
        role: "Account Manager",
        phone_number: "+63-945-871-8889",
        email: "arcastro@euodoo.com.ph",
        telephone:"+632-7088-7101",
        description:
           
                <>
                An Account Manager closely interacts with customers to answer questions or
                 address concerns. The daily tasks of an Account Management include overseeing 
                 routine issues as well as handling more complicated ones on behalf of their clients.


                </> 

          
    },
    {
        id:5,
        Image: projectmanager,
        name: "Giselle P. Bantug",
        role: "Project Manager",
        phone_number: "+63-977-728-8575",
        email: "gbantug@euodoo.com.ph",
        telephone:"+632-7088-7101",
        description:
            <>
                <>
                    Giselle began her career as an assistant to seven Audit and Tax Advisory Partners in 
                    an auditing firm. Her dedication and positive attitude towards work earned her the 
                    nickname "Girl Friday," and she quickly made a name for herself within the company. 
                    After a short while, Giselle was transferred to the Financial and Advisory Services 
                    Group as a Consultant and was sent to graduate school to pursue a Master's degree in Business Administration.
                </> <br/> <br/>
                <>
                    With over 15 years of experience, Giselle has honed her skills in research, planning, 
                    marketing, providing administrative support, and developing significant portions of 
                    project deliverables. Her expertise and knowledge have helped numerous organizations 
                    achieve their goals and drive their success.
                </> <br/> <br/>
                <>
                    In addition to her professional career, Giselle is also an online entrepreneur and 
                    an on-call event host. Her strong work ethic and entrepreneurial spirit make her a 
                    valuable asset to any team or project.
                </> <br/> <br/>
                <>
                    Giselle holds an undergraduate degree in Business Administration and received her Master 
                    of Business Administration from the Ateneo Graduate School of Business in 2006. Her education 
                    and experience have equipped her with the necessary skills to analyze complex business situations,
                     develop innovative solutions, and effectively communicate them to stakeholders.              
                 </> 
            </>,

        expertise:
        <div style={{display:"flex", flexDirection:'column'}}>
            <h6 className="py-3 card-title"style={{float:'left'}}>Areas of Expertise</h6>
        <ul className="px-3 bullets">
            <li>People Management</li>
            <li>Digital Strategy and Marketing</li>
            <li>Strategic Planning </li>
            <li>Product Development & Introduction</li>
            <li>Market Research</li>
        </ul>
        </div>
        
    },
    {
        id:6,
        Image: lett,
        name: "Lettice Marie Buere",
        role: "Sales & Marketing",
        phone_number: "+63-993-537-3902",
        email: "lebuere@euodoo.com.ph",
        telephone:"+632-7088-7101",
        description:
        <>
            <>
            Lettice is a highly motivated and skilled digital marketer, having recently 
            graduated with a marketing degree from the University of Makati and earned her 
            Google certification. Her experience as an intern on a multi-faceted marketing
             agency has equipped her with invaluable skills in social media management, 
             content creation, copywriting, and inbound and outbound lead generation. 
             Despite being a fresh graduate, she is confident and capable, always eager 
             to take on new challenges and further develop her expertise.
            </> <br/> <br/>
            <>
            As a student, Lettice was known for juggling multiple roles with ease. She was 
            a student leader, actively involved in various extracurricular activities, while 
            still maintaining an impressive academic record. Her strong work ethic, attention 
            to detail, and exceptional communication skills have set her apart as a natural leader.
            </> <br/> <br/>
            <>
            Outside of work, Lettice is an avid reader, always on the lookout for 
            new books to dive into. She enjoys thrift-shopping and has an eye for 
            finding unique treasures. When not engrossed in a book or shopping, she 
            can often be found jamming to Taylor Swift with her brother's guitar, 
            listening to KPop, or even belting out a Disney song or two. With her 
            diverse interests, Lettice is a well-rounded individual who brings a 
            creative and unique perspective to her work.            </> 

        </>,

    expertise:
    <div style={{display:"flex", flexDirection:'column'}}>
        <h6 className="py-3 card-title">Areas of Expertise</h6>
        <ul className="px-3 bullets">
        <li>Social Media Marketing</li>
        <li>Content Marketing</li>
        <li>Copywriting </li>
        <li>Advertising</li>
        <li>Market Research</li>
        <li>Brand Development</li>
        <li>Online Events Management</li>
        </ul>
    </div>
    },
    // {
    //     id:7,
    //     Image: sam,
    //     name: "Samuel Rimando",
    //     role: "Sales & Marketing",
    //     description:
    
    //         <>
    //             <>
    //                 Sam is a skilled creative professional with a Bachelor's degree in Broadcasting Arts from 
    //                 the University of Makati. He specializes in videography and photography, and has a basic 
    //                 knowledge of graphic design. Sam gained experience as a production assistant during his 
    //                 internship and also pursued it as a side-hustle. <br/><br/>
    //             </>
    //             <>
    //                 When the pandemic hit, Sam shifted his career and worked as a full-time barista at a well-known 
    //                 coffee shop for a year. However, his passion for his desired field never waned, and he made the 
    //                 decision to return to it to continue his growth and expertise. <br/><br/>
    //             </>
    //             <>
    //                 With his extensive experience in videography, photography, and graphic design, Sam is an invaluable
    //                 asset to any team. He is dedicated to delivering high-quality work and is always eager to learn and 
    //                 improve his skills. Whether working on a production set or behind the camera, Sam is committed to 
    //                 achieving outstanding results that exceed expectations.
    //             </>
    //         </> 
            
    // },
    {
        id:8,
        Image: miguel,
        name: "Juan Miguel Isidro Roxas",
        role: "Sales & Marketing",
        phone_number: "+63-927-718-8413",
        email: "jmiroxas@euodoo.com.ph",
        telephone:"+632-7088-7101",
        description:
    
            <>
                <>
               
                    Miguel is a highly motivated fourth-year marketing student at Adamson University, 
                    who has been an outstanding addition to the Sales & Marketing Department during 
                    his internship with the company. Miguel has brought his enthusiasm, creativity, and 
                    knowledge to every project he has worked on, and has quickly become a valuable member of the team.
                </>  <br/><br/> 
                <>
               
                    With his previous experience as a member of the marketing team in high school and college, 
                    Miguel brings a wealth of knowledge to his role. He has also taken on leadership roles 
                    in various organizations both inside and outside the school, demonstrating his ability 
                    to work well with others and manage projects effectively.
                </>  <br/><br/> 
                <>
                    
                    Miguel's dedication and strong work ethic extend beyond his academic pursuits. He has also 
                    supported himself while in college by working in an accounting firm and a construction company. 
                    His ability to balance his work, academic, and extracurricular commitments is a testament to 
                    his discipline and time-management skills.
                </>  <br/><br/> 
                <>
                    
                    Miguel has a bright future ahead of him in the marketing industry. He has already made a 
                    positive impact on the company and has demonstrated a strong aptitude for marketing and sales.
                </> 
            </> 
    },
    {
        id:9,
        Image: stephen,
        name: "Stephen Solito",
        role: "Web and App Developer",
        phone_number: "+63-995-084-2694",
        email: "sabsolito@euodoo.com.ph",
        telephone:"+632-7088-7101",
        description:
        
            <>
                <>

                    Stephen is a talented and driven recent graduate who has already made a significant impact at 
                    Euodoo Technologies. Since joining the company, he has been assigned to multiple projects and has 
                    displayed a strong willingness to learn and grow in his role. Stephen has taken every opportunity 
                    to build his skills, develop new expertise, and gain valuable experience in his industry.
                </> <br/><br/>
                <>

                    As a new employee, Stephen has embraced the company's culture and is committed to providing 
                    exceptional service to his clients. He is working closely with his colleagues, learning from 
                    more experienced team members, and building strong relationships that will help him succeed in his career.
                </> <br/><br/>
                <>

                    With his natural talent, strong work ethic, and commitment to professional growth, Stephen is 
                    poised to achieve great things in his career. He is a dynamic and motivated individual who is 
                    always willing to go the extra mile to ensure that his clients receive the best possible service.
                </> 
            </> 
            
           
        ,

    expertise:
    <div style={{display:"flex", flexDirection:'column'}}>
            <h6 className="py-3 card-title">Areas of Expertise</h6>
        <ul className="px-3 bullets">
        <li>Web and App Development</li>
        <li> Back-End Development</li>
        <li>PHP, Java, Python</li>
        </ul>
    </div>
    },
    {
        id:10,
        Image: vivian,
        name: "Vivian Miranda",
        role: "Web and App Developer",
        phone_number: "+63-945-276-5455",
        email: "vmmiranda@euodoo.com.ph",
        telephone:"+632-7088-7101",
        description:
        <>
            <>
                Vivian is a highly skilled and driven IT professional with a Bachelor of Science in Information 
                Technology from Centro Escolar University - Makati. With a broad range of IT skills, including 
                web development, game development, CCNA, and system development, she is adept at working across 
                various programming languages and platforms.
            </> <br/><br/>
            <>
                With a passion for learning and a strong desire to build positive relationships with team members, 
                Vivian consistently seeks out new experiences and opportunities to enhance her skills. She brings 
                a wealth of knowledge and expertise to her role as a Web and App developer, where she consistently 
                applies her skills and knowledge to produce high-quality work.
            </> <br/><br/>
            <>
                In addition to her technical expertise, Vivian is also known for her excellent interpersonal 
                skills. She works collaboratively with team members to identify and address project needs, and is 
                always eager to help others succeed. Her dedication to her craft and her commitment to building 
                strong relationships make her an asset to any team.
            </> 
        </>,

        expertise:
        <div style={{display:"flex", flexDirection:'column'}}>
            <h6 className="py-3 card-title">Areas of Expertise</h6>
        <ul className="px-3 bullets">
            <li>Web Development</li>
            <li> Back-End Development</li>
            <li>Java, Javascript, PHP</li>
            <li>MongoDB, SQL</li>
        </ul>
        </div>
    },
    {
        id:11,
        Image: nerisse,
        name: "Nerisse P. Isada",
        role: "Web and App Developer",
        phone_number: "+63-919-009-0510",
        email: "ngpisada@euodoo.com.ph",
        telephone:"+632-7088-7101",
        description:
        <>
            <>
                Nerisse is a passionate and self-driven recent Mapua University - Makati graduate with a dergree
                of Bachelor of Science in Computer Science. She specializes in Artificial Intelligence as she 
                worked with Natural Language Processing (NLP), Machine Learning, and Neural Network simulations 
                for numerous projects.
            </><br/><br/>
            <>
                As she starts her career with Euodoo Technologies, Nerisse is driven to learn and explore as she 
                collaborates closely with experienced colleagues. She is eager to meet new challenges as she 
                continually improves her skills and widens her scope to provide outstanding work.
            </><br/><br/> 
            <>
                With her dedication and commitment to professional growth, Nerisse is set to achieve her goals. 
                Along with that, her skills and expertise is a valuable asset to the company.
            </>
           
        </>,

        expertise:
        <div style={{display:"flex", flexDirection:'column'}}>
            <h6 className="py-3 card-title">Areas of Expertise</h6>
        <ul className="px-3 bullets">
            <li>Web Development</li>
            <li>Java, Python, PHP</li>
            <li>Artificial Intelligence</li>
        </ul>
        </div>
    },
    // {
    //     id:11,
    //     Image: unknown,
    //     name: "Unknown",
    //     role: "Sales & Admin",
    //     description: <>
    //         Customer service skills can help administrators in their interactions 
    //         with clients and customers. Many sales administrators work directly with 
    //         customers, processing invoices, handling orders, answering questions and 
    //         facilitating the sales process. Developing customer service skills can help 
    //         sales administrators remain friendly, professional and patient in their communications.
    //     </>
    // }

]


// for (i = 0; i < team.length; i++)
//  console.log((i+1) + ": " + array[i]);

export default team;