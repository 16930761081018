import sales from "../assets/careerpictures/sales.jpg";
import book from "../assets/careerpictures/book.jpg";
import admin from "../assets/careerpictures/admin.png";
import web from "../assets/careerpictures/web.png";

 const jobs = [
    {
        title: "Sales and Marketing Assistant",
        img: sales,
        description: `
        Our company is looking to hire a marketing assistant who will be working closely with 
        our marketing team to develop and implement marketing plans and strategies for our brands. 
        As a successful hire, you will be tasked with helping identify marketing trends and 
        opportunities for growth and creating marketing materials. You will give 
        presentations about our ongoing campaigns and administrative support to our marketing and sales teams. 
        `,
        roles: [ 

            <ul className="">
                <li>
                    Performing administrative tasks such as answering phones, greeting clients, and scheduling appointments.
                </li>
                <li>
                    Providing customer service by answering questions about products or services, processing orders, and handling complaints.
                </li>
                <li>
                    Maintaining relationships with current customers by providing them with product information and promotional offers.
                </li>
                <li>
                    Preparing proposals for new business opportunities and managing existing accounts.
                </li>
                <li>
                    Preparing marketing materials such as brochures, flyers, and catalogs.
                </li>
                <li>
                    Coordinating events such as trade shows, conventions, seminars, or product launches.
                </li>
                <li>
                    Developing and executing marketing strategies for specific products or services.
                </li>
                <li>
                    Coordinating with other departments to ensure smooth operations.
                </li>
                <li>
                    Giving presentations.
                </li>
                <li>
                    Providing administrative support to the marketing and sales team.
                </li>
                <li>
                    Preparing, formatting, and editing a range of documents.
                </li>
                <li>
                    General office duties.
                </li>
                <li>
                    Creating and interpreting a variety of reports.
                </li>
                <li>
                    Organizing market research.
                </li>
                <li>
                    Updating social media accounts.
                </li>
            </ul>
     
        ],
        qualifications: [
            <ul className="pt-3">
                <li>Bachelor’s degree in marketing, business, or a related field.</li>
                <li>Administration or sales and marketing assistant experience.</li>
                <li>Effective written and verbal communication skills.</li>
                <li>A high level of attention to detail.</li>
                <li>Ability to work effectively within a team and independently.</li>
                <li>Experience using computers for a variety of tasks.</li>
                <li>Competency in Microsoft applications including Word, Excel, and Outlook.</li>
                <li>Good organization skills.</li>
                <li>Related job and internship experience.</li>
                <li>Digital marketing experience.</li>
                <li>Strong analytical and planning skills.</li>
                <li>Good communication and presentation skills.</li>
                <li>Excellent problem-solving skills.</li>
            </ul>
        ],
        link: 
            <a href="https://jobseeker.kalibrr.com/c/euodo-technologies-inc/jobs/215357/sales-and-marketing-assistant" rel="noreferrer" target="_blank"><p className='see-more'>See More</p></a>,
        
    },

    {
        title: "Web and App Developer",
        img: web,
        description: `
        We are looking for a skilled web developer who will be 
        responsible for developing and/or designing websites for our company. 
        You will be working alongside a team of other developers in planning 
        and developing software solutions and web applications, supporting, and 
        maintaining a company's websites and digital products. 
        `,
        roles: [
            <ul className="">
                <li>Developing front-end website architecture.</li>
                <li>Designing user interactions on web pages.</li>
                <li>Developing back-end website applications.</li>
                <li>Creating servers and databases for functionality.</li>
                <li>Ensuring cross-platform optimization for mobile phones.</li>
                <li>Ensuring responsiveness of applications.</li>
                <li>Working alongside graphic designers for web design features.</li>
                <li>Designing and developing APIs.</li>
                <li>Meeting both technical and consumer needs.</li>
                <li>Staying abreast of developments in web applications and programming languages.</li>
            </ul>
           
        ],
        qualifications: [
            <ul className="pt-3">
                <li>Degree in Computer Science, Information Technology, or any computer related.</li>
                <li>Solid understanding of HTML, CSS, and JavaScript.</li>
                <li>Knowledge and experience in Vue.js and Node.js are Preferred.</li>
                <li>Proficient with at least one programming language like Java, PHP, Python, Ruby, .Net etc…</li>
                <li>Familiarity with database technologies such as MySQL, Oracle, and MongoDB.</li>
                <li>Familiarity with version control such as Git.</li>
                <li>Familiarity with mobile development is a plus.</li>
                <li>Strong organizational and project management skills.</li>
                <li>Excellent verbal communication skills.</li>
                <li>Good problem-solving skills.</li>
                <li>Attention to detail.</li>
            </ul>
        ],
        link: 
            <a href="https://jobseeker.kalibrr.com/c/euodo-technologies-inc/jobs/215358/web-and-app-developer" rel="noreferrer"  target="_blank"><p className='see-more'>See More</p></a>,
        
    },

    {
        title: "Bookkeeper",
        img: book,
        description: `
        We would like to hire a Bookkeeper to take care of all our 
        company day to day financial tasks. You will be responsible 
        for accounts payable and receivable along with all payroll, 
        bank account management, and reconciliation tasks. To do well 
        in this role you should have previous experience as a Bookkeeper.
        `,
        roles: [
            <ul className="">
                <li>Recording day-to-day financial transactions and completing the posting process.</li>
                <li>Reconciling sales taxes at the end of each month.</li>
                <li>Monitoring financial transactions and reports.</li>
                <li>Processing accounts receivable and payable.</li>
                <li>Processing checks.</li>
                <li>Understanding ledgers.</li>
                <li>Working with an accountant and company auditor when necessary.</li>
            </ul>
        ],
        qualifications: [
            <ul className="pt-3">
                <li>Associate’s degree in accounting or business administration. Excellent knowledge of general accounting principles.</li>
                <li>High school diploma.</li>
                <li>Knowledge of bookkeeping software.</li>
                <li>At least 2 years of bookkeeping experience, preferably within a business-services environment.</li>
                <li>Excellent communication skills, both verbal and written.</li>
                <li>Attention to detail and accuracy of work.</li>
                <li>Able to prepare, review and understand a financial statement.</li>
            </ul>
        ],
        link:
            <a href="https://jobseeker.kalibrr.com/c/euodo-technologies-inc/jobs/215360/bookkeeper" rel="noreferrer"  target="_blank"><p className='see-more'>See More</p></a>,
        
    },

    {
        title: "Administrative Officer",
        img: admin,
        description: `
        We are looking to hire a highly organized administrative officer 
        to perform all administrative and clerical duties necessary for 
        effective office management. The Administrative Officer's responsibilities 
        include overseeing the activities of office cleaning staff and maintenance 
        vendors activities, and typing and proofreading various company documents. 
        You should also be able to organize flights, transportation, and 
        accommodation for company representatives and executives.
        `,
        roles: [
            <ul className="">
                <li>Answering telephone calls, responding to queries, and replying to emails</li>
                <li>Preparing expense reports and office budgets.</li>
                <li>Coordinating schedules, arranging meetings, distributing memos and reports, 
                    and ensuring that everyone is kept current with necessary company news and information</li>
                <li>Ensuring office supplies are maintained, including checking inventory and working with vendors to 
                    always ensure adequate levels of necessary supplies.</li>
                <li>Systematically filing important company documents and ensuring the confidentiality and security
                    of files and filing systems.</li>
                <li>Forwarding all correspondence, such as letters and packages, to staff members.</li>
                <li>Scheduling meetings and booking conference rooms.</li>
                <li>Hiring maintenance vendors to repair or replace damaged office equipment.</li>
                <li>Assisting the HR department with job postings and interviews.</li>

            </ul>
        ],
        qualifications: [
            <ul className="pt-3">
                <li>Bachelor’s degree in marketing, business, or a related field.</li>
                <li>Proven experience working in an office environment.</li>
                <li>Proficiency in all Microsoft Office applications.</li>
                <li>Working knowledge of business management.</li>
                <li>The ability to multitask.</li>
                <li>Excellent organizational skills.</li>
                <li>Effective communication skills.</li>
                <li>Exceptional customer service skills.</li>
            </ul>
        ],
        link: 
            <a href="https://jobseeker.kalibrr.com/c/euodo-technologies-inc/jobs/215359/administrative-officer" rel="noreferrer"  target="_blank"><p className='see-more'>See More</p></a>,
        
    },

]
export default jobs;